// src/components/message/MessageList.tsx
import React from 'react'
import { Box, List } from '@mui/material'
import MessageComponent from './Message'
import { Message, Profile } from 'src/types/models'
import chat from '../../assets/media/chat_placeholder.png'
interface MessageListProps {
  messages?: Message[]
  myUserId: number
  users: Profile[]
}

const MessageList: React.FC<MessageListProps> = ({
  messages,
  myUserId,
  users,
}) => {
  let lastSender = -1
  return (
    <Box flex={1} overflow="auto" p={2}>
      <List>
        {messages && messages.length > 0 ? (
          messages.map((message, index) => {
            const showAvatar = lastSender !== message.sender
            const paddingBetweenMessages = showAvatar ? 2 : 0
            lastSender = message.sender
            const isOwnMessage = message.sender === myUserId // Replace with the actual logged-in user

            return (
              <Box key={index} pt={index === 0 ? 0 : paddingBetweenMessages}>
                <MessageComponent
                  users={users}
                  message={message}
                  isOwnMessage={isOwnMessage}
                  showAvatar={showAvatar}
                />
              </Box>
            )
          })
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="60vh"
          >
            <img src={chat} alt="chat" style={{ width: '170px' }} />
          </Box>
        )}
      </List>
    </Box>
  )
}

export default MessageList
