import React, { useState } from 'react';
import { Box, Typography, Avatar, MenuItem, Select, FormControl, OutlinedInput, Snackbar, Alert, colors } from '@mui/material';
import CustomTextField from './CustomTextField';
import CustomButton from '../CustomButton';
import { updateBusinessApplication } from '../../api/apiService'; // Import the function from apiService.ts

const categories = [
  'Home goods', 'Free stuff', 'Clothing', 'Electronics', 
  'Pet supplies', 'Sport', 'Books', 'Toys', 'Tools', 
  'Furniture', 'Appliances', 'Garden', 'Automotive', 
  'Health', 'Beauty', 'Groceries', 'Music', 'Movies'
];

const BusinessForm: React.FC = () => {
  const [businessName, setBusinessName] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [backgroundImage, setBackgroundImage] = useState<File | null>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [successMessage, setSuccessMessage] = useState<string>('');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>, setAvatar?: React.Dispatch<React.SetStateAction<string | null>>) => {
    const file = e.target.files?.[0] || null;
    setFile(file);
    if (file && setAvatar) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('business_name', businessName);
    formData.append('description', description);
    formData.append('website_link', website);
    formData.append('location', location);
    formData.append('category', category);
    if (backgroundImage) formData.append('background_image', backgroundImage);
    if (logo) formData.append('logo', logo);

    try {
      const response = await updateBusinessApplication(formData);
      console.log('Business application updated:', response);
      setErrors({}); // Clear errors on success
      setSuccessMessage('Business application submitted successfully!');
    } catch (error: any) {
      console.error('Error updating business application:', error);
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: '0 auto' }}>
      <Typography variant="h6">Business details</Typography>
      <Box py={1} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar sx={{ width: 80, height: 80, borderRadius: 1 }} src={avatarSrc || ''} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1 }}>
          <CustomButton variant="contained" component="label">
            Upload Logo
            <input hidden accept="image/*" type="file" onChange={(e) => handleFileChange(e, setLogo, setAvatarSrc)} />
          </CustomButton>
          <Typography variant="caption" color="textSecondary">Select your photo up to 5mb</Typography>
          {errors.logo && <Typography color="error">{errors.logo[0]}</Typography>}
        </Box>
      </Box>
      <CustomTextField
        label="Business Name"
        placeholder='Name'
        fullWidth
        inputProps={{ maxLength: 100 }}
        value={businessName}
        onChange={(e) => setBusinessName(e.target.value)}
        error={!!errors.business_name}
        helperText={errors.business_name ? errors.business_name[0] : ''}
      />
      <CustomTextField
        label="Website"
        placeholder='Website link'
        fullWidth
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
        error={!!errors.website_link}
        helperText={errors.website_link ? errors.website_link[0] : ''}
      />
      <CustomTextField
        label="Location"
        placeholder='Company address'
        fullWidth
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        error={!!errors.location}
        helperText={errors.location ? errors.location[0] : ''}
      />
      <FormControl fullWidth error={!!errors.category}>
        <Select
          displayEmpty
          value={category}
          onChange={(e) => setCategory(e.target.value as string)}
          input={<OutlinedInput />}
          renderValue={selected => selected ? selected : 'Category'}
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        {errors.category && <Typography color="error">{errors.category[0]}</Typography>}
      </FormControl>
      <CustomTextField
        label="Description"
        multiline
        rows={4}
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        error={!!errors.description}
        helperText={errors.description ? errors.description[0] : ''}
      />
      <CustomButton variantType="outlined" color="secondary" sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}>
        Upload Background Image
        <input hidden accept="image/*" type="file" onChange={(e) => handleFileChange(e, setBackgroundImage)} />
      </CustomButton>
      {errors.background_image && <Typography color="error">{errors.background_image[0]}</Typography>}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomButton type="submit" variant="contained" color="primary">Update Business</CustomButton>
        <CustomButton variant="outlined" onClick={() => {
          setBusinessName('');
          setDescription('');
          setWebsite('');
          setLocation('');
          setCategory('');
          setBackgroundImage(null);
          setLogo(null);
          setAvatarSrc(null);
          setErrors({});
        }}>Reset</CustomButton>
      </Box>
      <Snackbar open={!!successMessage} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BusinessForm;
