import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'
import logo from '../assets/media/mainlogo.svg'

const Footer: React.FC = () => (
  <Box sx={{ backgroundColor: '#DAEBEE', mt: 5, width: '100%', py: 4 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        px: 5,
        pb: 2,
        borderBottom: '1px solid white',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ height: '60px' }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
        <Link
          href="#mission"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            marginRight: '15px',
          }}
        >
          Mission
        </Link>
        <Link
          href="#about"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            marginRight: '15px',
          }}
        >
          About
        </Link>
        <Link href="#faq" style={{ textDecoration: 'none', color: 'inherit' }}>
          Faq
        </Link>
      </Box>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 2,
        px: 5,
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © 2024 BB. All rights reserved.
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link
          href="https://www.facebook.com"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            marginRight: '15px',
          }}
        >
          <FaFacebook size={30} /> {/* Using actual icon */}
        </Link>
        <Link
          href="https://www.twitter.com"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            marginRight: '15px',
          }}
        >
          <FaTwitter size={30} /> {/* Using actual icon */}
        </Link>
        <Link
          href="https://www.instagram.com"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <FaInstagram size={30} /> {/* Using actual icon */}
        </Link>
      </Box>
    </Box>
  </Box>
)

export default Footer
