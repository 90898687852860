import React from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import VideocamIcon from '@mui/icons-material/Videocam'
import { Chat, Profile } from 'src/types/models'

interface ChatHeaderProps {
  chat: Chat
  users: Profile[]
  myId: number
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ chat, users, myId }) => {
  const getChatName = (chat: Chat) => {
    const id = chat.participants.find((p) => p !== myId)
    const user = users.find((u) => u.id === id)
    if (user) {
      return `${user.first_name} ${user.last_name}`
    }
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      borderBottom="1px solid #ddd"
    >
      <Box>
        {chat && (
          <>
            <Typography variant="h6">
              {chat.chat_name || getChatName(chat)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {chat.participants.length} members, {chat.active_users.length}{' '}
              online
            </Typography>
          </>
        )}
      </Box>
      <Box>
        <IconButton disabled>
          <PhoneIcon />
        </IconButton>
        <IconButton disabled>
          <VideocamIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ChatHeader
