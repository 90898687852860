import React, { useState } from 'react'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  Container,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from '../config/theme'
import Suggestions from '../components/feed/Suggestions' // Adjust import path as needed
import useFetchUserFriends from 'src/hooks/useFetchUserFriends'

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  paddingRight: '5px',
}))

const ScrollableList = styled(List)(({ theme }) => ({
  height: 'calc(100% - 40px)',
  overflowY: 'auto',
  py: 0,
}))

const SeeMore = styled(Typography)({
  color: colors.brandGray,
  cursor: 'pointer',
  borderBottom: '1px solid',
  marginBottom: '8px',
  pb: '3px',
  width: 'max-content',
  fontSize: '14px',
})

const DynamicContainer = styled(Container)<{ friendCount: number }>(
  ({ friendCount, theme }) => ({
    padding: '20px',
    height: friendCount < 10 ? 'auto' : '100%',
    overflow: friendCount >= 10 ? 'hidden' : 'visible',
  })
)

const CommunHelper: React.FC = () => {
  const [expanded, setExpanded] = useState(false)
  const [suggestionsLoaded, setSuggestionsLoaded] = useState(false)
  const { friends, loading } = useFetchUserFriends()

  const handleToggleExpand = () => {
    setExpanded((prev) => !prev)
  }

  const handleSuggestionsLoadingComplete = () => {
    setSuggestionsLoaded(true)
  }

  const suggestionsCount = 10 - friends.length

  return (
    <DynamicContainer friendCount={friends.length} disableGutters>
      <Header>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: '600' }}>
          Friends
        </Typography>
        <SeeMore onClick={handleToggleExpand}>
          {expanded ? 'Show Less' : 'See All'}
        </SeeMore>
      </Header>
      {loading ? (
        Array.from(new Array(6)).map((_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            width="100%"
            height={50}
            sx={{ mb: 2 }}
          />
        ))
      ) : (
        <ScrollableList sx={{ pt: 0 }}>
          {friends
            .slice(0, expanded ? friends.length : 10)
            .map((friend, index) => (
              <ListItem key={index} alignItems="center" sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    alt={`${friend.first_name} ${friend.last_name}`}
                    src={friend.profile_pic ? `${friend.profile_pic}` : ''}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${friend.first_name} ${friend.last_name}`}
                  primaryTypographyProps={{ display: 'inline' }}
                  secondaryTypographyProps={{
                    display: 'inline',
                    color: 'textSecondary',
                  }}
                />
              </ListItem>
            ))}
        </ScrollableList>
      )}
      {friends.length < 10 && (
        <>
          <Typography
            variant="h6"
            sx={{ fontWeight: '600' }}
            gutterBottom
            mt={2}
          >
            Suggestions
          </Typography>
          <Suggestions
            loading={!suggestionsLoaded}
            onLoadingComplete={handleSuggestionsLoadingComplete}
            suggestionsCount={suggestionsCount}
          />
        </>
      )}
    </DynamicContainer>
  )
}

export default CommunHelper
