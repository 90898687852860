import React, { useState, useRef } from 'react';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Clear } from '@mui/icons-material';
import imageCompression from 'browser-image-compression';

interface MessageInputProps {
  addMessage: (message: {
    text?: string;
    sender: number;
    attachment?: File;
    isFile?: boolean;
  }) => void;
  myUserId: number;
}

const MessageInput: React.FC<MessageInputProps> = ({
  addMessage,
  myUserId,
}) => {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSend = () => {
    if (!message.trim() && !file) return;

    let newMessage: {
      text?: string;
      sender: number;
      attachment?: File;
      isFile: boolean;
    };

    if (file) {
      newMessage = {
        sender: myUserId,
        attachment: file,
        isFile: true,
      };
    } else {
      newMessage = {
        sender: myUserId,
        text: message,
        isFile: false,
      };
    }

    addMessage(newMessage);
    setMessage('');
    setFile(null);
  };

  const handleAttachFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const compressedFile = await compressImage(event.target.files[0]);
      setFile(compressedFile);
      setMessage(compressedFile.name); // Show file name in input field
    }
  };

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Image compression error:', error);
      return file;
    }
  };

  const handleResetFile = () => {
    setFile(null);
    setMessage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Box display="flex" p={2} borderTop="1px solid #ddd">
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Your message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={file !== null}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSend();
          }
        }}
        InputProps={{
          style: { color: file ? 'black' : 'inherit' }, // Ensures black color for file name
          startAdornment: (
            <InputAdornment position="start">
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleAttachFile}
              />
              <IconButton onClick={() => fileInputRef.current?.click()}>
                <AttachFileIcon />
              </IconButton>
              {file && (
                <IconButton onClick={handleResetFile}>
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton color="primary" onClick={handleSend}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: file ? 'black' : 'inherit' }, // Ensures consistent label color
        }}
      />
    </Box>
  );
};

export default MessageInput;
