// store.js
import { Chat } from 'src/types/models'
import create from 'zustand'

export interface ChatStore {
  activeChat: Chat | null
  messageCount: number
  setActiveChat: (chat: Chat) => void
  setMessageCount: (count: number) => void
  markMessagesAsRead: (chatId: number, userId: number) => void
}
const useChatStore = create<ChatStore>((set) => ({
  activeChat: null,
  messageCount: 0,
  setActiveChat: (chat: Chat | null) => set({ activeChat: chat }),
  setMessageCount: (count: number) => set({ messageCount: count }),
  markMessagesAsRead: (chatId: number, userId: number) => {
    set((state) => {
      if (!state.activeChat || state.activeChat.id !== chatId) return state

      const updatedMessages = state.activeChat.messages.map((message) => {
        if (message.sender !== userId && !message.read_by.includes(userId)) {
          return { ...message, read_by: [...message.read_by, userId] }
        }
        return message
      })

      return { activeChat: { ...state.activeChat, messages: updatedMessages } }
    })
  },
}))

export default useChatStore
