import { Link, useLocation } from 'react-router-dom'
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Badge,
} from '@mui/material'
import { styled } from '@mui/system'
import { colors } from '../config/theme'
import { Icons } from '../assets/index'
import ad from '../assets/media/adPic.png'
import MailIcon from '@mui/icons-material/Mail'
import useChatStore from 'src/store/unreadMessagesStore'

const DrawerContainer = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
}))

const StyledListItemIcon = styled(ListItemIcon)({
  paddingLeft: '15px',
})

const DrawerHeader = styled(Typography)(({ theme }) => ({
  padding: '15px 0px 0px 30px',
  textAlign: 'start',
  fontWeight: '900',
  fontSize: '20px',
  backgroundColor: theme.palette.primary.main,
  color: 'black',
  margin: '0px 0px 20px 0px',
  alignContent: 'center',
}))

const MenuCenter = styled(Box)({
  height: '80%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const AdsContainer = styled(Box)`
  text-align: start;
  margin: 15px 20px;

  @media (max-width: 600px) {
    display: none;
  }
`

const FooterContainer = styled(Box)({
  position: 'absolute',
  bottom: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
})

const FooterLink = styled(Link)({
  margin: '0 10px',
  color: colors.brandGray,
  textDecoration: 'none',
  fontSize: '0.8rem',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const LeftDrawer: React.FC = () => {
  const location = useLocation()
  const { messageCount } = useChatStore()

  return (
    <DrawerContainer>
      <DrawerHeader
        variant="h6"
        noWrap
        sx={{
          fontSize: { xs: '1.5rem', sm: '1.5rem' },
          mt: { sm: '1.5rem', xs: '1.5rem', md: '1rem' },
          mb: { sm: '3rem', xs: '3rem', md: '1rem' },
        }}
      >
        {/* <img src={logo} alt="" /> */}
        Free Life Nation
      </DrawerHeader>
      <MenuCenter>
        <List>
          <ListItemButton
            component={Link}
            to="/"
            selected={location.pathname === '/'}
          >
            <StyledListItemIcon>
              <Icons.homeIcon
                color={location.pathname === '/' ? 'black' : colors.brandGray50}
                size={26}
              />
            </StyledListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color:
                    location.pathname === '/' ? 'black' : colors.brandGray50,
                  fontSize: {
                    md: '0.95rem',
                    lg: '0.95rem',
                    xl: '1.3rem',
                    xs: '1.2rem',
                    sm: '1.2rem',
                  },
                  marginLeft: {
                    md: '-1.3rem',
                    lg: '-1.3rem',
                    xs: '-0.8rem',
                    sm: '-0.6rem',
                  },
                  fontWeight: '500',
                }}
              >
                Feed
              </Typography>
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/profile"
            selected={location.pathname === '/profile'}
          >
            <StyledListItemIcon>
              <Icons.profileIcon
                color={
                  location.pathname === '/profile'
                    ? 'black'
                    : colors.brandGray50
                }
                size={26}
              />
            </StyledListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color:
                    location.pathname === '/profile'
                      ? 'black'
                      : colors.brandGray50,
                  fontSize: {
                    md: '0.95rem',
                    lg: '0.95rem',
                    xl: '1.3rem',
                    xs: '1.2rem',
                    sm: '1.2rem',
                  },
                  marginLeft: {
                    md: '-1.3rem',
                    lg: '-1.3rem',
                    xs: '-0.8rem',
                    sm: '-0.6rem',
                  },
                  fontWeight: '500',
                }}
              >
                My Posts
              </Typography>
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/messages"
            selected={location.pathname === '/messages'}
          >
            <StyledListItemIcon>
              <Icons.chatIcon
                color={
                  location.pathname === '/messages'
                    ? 'black'
                    : colors.brandGray50
                }
                size={24}
              />
            </StyledListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color:
                    location.pathname === '/messages'
                      ? 'black'
                      : colors.brandGray50,
                  fontSize: {
                    md: '0.95rem',
                    lg: '0.95rem',
                    xl: '1.3rem',
                    xs: '1.2rem',
                    sm: '1.2rem',
                  },
                  marginLeft: {
                    md: '-1.3rem',
                    lg: '-1.3rem',
                    xs: '-0.8rem',
                    sm: '-0.6rem',
                  },
                  fontWeight: '500',
                }}
              >
                Messages
              </Typography>
            </ListItemText>
            {messageCount > 0 && (
              <Badge badgeContent={messageCount} color={'secondary'}>
                <MailIcon color="action" />
              </Badge>
            )}
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/shop"
            selected={location.pathname === '/shop'}
          >
            <StyledListItemIcon>
              <Icons.shopIcon
                color={
                  location.pathname === '/shop' ? 'black' : colors.brandGray50
                }
                size={24}
              />
            </StyledListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color:
                    location.pathname === '/shop'
                      ? 'black'
                      : colors.brandGray50,
                  fontSize: {
                    md: '0.95rem',
                    lg: '0.95rem',
                    xl: '1.3rem',
                    xs: '1.2rem',
                    sm: '1.2rem',
                  },
                  marginLeft: {
                    md: '-1.3rem',
                    lg: '-1.3rem',
                    xs: '-0.8rem',
                    sm: '-0.6rem',
                  },
                  fontWeight: '500',
                }}
              >
                Shop
              </Typography>
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/giveaways"
            selected={location.pathname === '/giveaways'}
          >
            <StyledListItemIcon>
              <Icons.giveawayIcon
                color={
                  location.pathname === '/giveaways'
                    ? 'black'
                    : colors.brandGray50
                }
                size={24}
              />
            </StyledListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color:
                    location.pathname === '/giveaways'
                      ? 'black'
                      : colors.brandGray50,
                  fontSize: {
                    md: '0.95rem',
                    lg: '0.95rem',
                    xl: '1.3rem',
                    xs: '1.2rem',
                    sm: '1.2rem',
                  },
                  marginLeft: {
                    md: '-1.3rem',
                    lg: '-1.3rem',
                    xs: '-0.8rem',
                    sm: '-0.6rem',
                  },
                  fontWeight: '500',
                }}
              >
                Giveaways & Events
              </Typography>
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/settings"
            selected={location.pathname === '/settings'}
          >
            <StyledListItemIcon>
              <Icons.settingIcon
                color={
                  location.pathname === '/settings'
                    ? 'black'
                    : colors.brandGray50
                }
                size={26}
              />
            </StyledListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color:
                    location.pathname === '/settings'
                      ? 'black'
                      : colors.brandGray50,
                  fontSize: {
                    md: '0.95rem',
                    lg: '0.95rem',
                    xl: '1.3rem',
                    xs: '1.2rem',
                    sm: '1.2rem',
                  },
                  marginLeft: {
                    md: '-1.3rem',
                    lg: '-1.3rem',
                    xs: '-0.8rem',
                    sm: '-0.6rem',
                  },
                  fontWeight: '500',
                }}
              >
                Settings
              </Typography>
            </ListItemText>
          </ListItemButton>
        </List>
        <AdsContainer>
          <Typography
            variant="h6"
            style={{ fontSize: '1.1rem', fontWeight: '600' }}
          >
            Ads
          </Typography>
          <img
            src={ad}
            alt="Ad"
            style={{ width: '100%', marginTop: '5px', borderRadius: '5px' }}
          />
          <Typography
            variant="body2"
            style={{ fontSize: '0.85rem', margin: '3px 0px' }}
          >
            Accelerate your coding with Codura
          </Typography>
          <Typography
            variant="body2"
            style={{ fontSize: '0.7rem' }}
            color={colors.brandGray}
          >
            www.codura.cc
          </Typography>
        </AdsContainer>
      </MenuCenter>
      <FooterContainer>
        <FooterLink to="/">About</FooterLink>
        <FooterLink to="/">Privacy</FooterLink>
        <FooterLink to="/">Help</FooterLink>
      </FooterContainer>
    </DrawerContainer>
  )
}

export default LeftDrawer
