import React, { useRef, useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, IconButton, Skeleton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar, Alert } from '@mui/material';
import GiveawayMain from '../components/giveaway/GiveawayMain';
import GiveawayList from '../CompLists/GiveawayList';
import EventItemsList from '../CompLists/EventItemsList';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useGiveaways from '../hooks/useGiveaways';
import useEvents from '../hooks/useEvents';
import { submitEventApplication, fetchUserId } from '../api/apiService';
import { colors } from '../config/theme';
import CustomButton from '../components/CustomButton';
import imageCompression from 'browser-image-compression';

const GiveawaysPage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { giveaways, loading: loadingGiveaways, error: errorGiveaways } = useGiveaways();
  const { events, loading: loadingEvents, error: errorEvents } = useEvents();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [formData, setFormData] = useState<{
    event_name: string;
    link: string;
    location: string;
    date: string;
    price: string;
    background_image: File | null;
    user: number | null;
  }>({
    event_name: '',
    link: '',
    location: '',
    date: '',
    price: '',
    background_image: null,
    user: null,
  });

  useEffect(() => {
    const getUserId = async () => {
      try {
        const userId = await fetchUserId();
        setFormData((prevData) => ({
          ...prevData,
          user: userId,
        }));
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    getUserId();
  }, []);

  const handleFormChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (files && files[0] && name === 'background_image') {
      const compressedFile = await compressImage(files[0]);
      setFormData((prevData) => ({
        ...prevData,
        [name]: compressedFile,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Image compression error:', error);
      return file;
    }
  };

  const handleSubmit = async () => {
    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) data.append(key, value as string | Blob);
    });

    try {
      await submitEventApplication(data);
      setSnackbarMessage('Wow! Event submitted successfully! 🎉');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setDialogOpen(false);
    } catch (error) {
      console.error('Error submitting event:', error);
      setSnackbarMessage('Oops! Event submission failed. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth * 0.32;
      containerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  if (errorGiveaways || errorEvents) {
    return <Typography>Error loading data</Typography>;
  }

  return (
    <Container sx={{ mt: 3, padding: '20px', mb: 2 }} disableGutters>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
        {loadingGiveaways ? (
          <Skeleton variant="text" width={200} height={40} sx={{ mb: 0 }} />
        ) : (
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            Giveaways
          </Typography>
        )}
        <Button variant="contained" sx={{ boxShadow: 'none', backgroundColor: colors.brandSecondary, color: 'white', fontSize: '10px', padding: '8px 12px', '&:hover': { backgroundColor: 'black', opacity: 0.8, boxShadow: 'none' }, }} onClick={() => setDialogOpen(true)}>
          Add Event
        </Button>
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add Event</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Event Name"
            name="event_name"
            value={formData.event_name}
            onChange={handleFormChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Date"
            name="date"
            type="datetime-local"
            value={formData.date}
            onChange={handleFormChange}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Location"
            name="location"
            value={formData.location}
            onChange={handleFormChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Price"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleFormChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Link"
            name="link"
            type="url"
            value={formData.link}
            onChange={handleFormChange}
            sx={{ mb: 2 }}
          />
          <CustomButton variant="contained" color="primary" component="label">
            Upload Background Image
            <input
              type="file"
              name="background_image"
              hidden
              accept="image/*"
              onChange={handleFormChange}
            />
          </CustomButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} sx={{ color: colors.brandGray50 }}>Cancel</Button>
          <CustomButton variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={2} mt={1} mb={3}>
        <Grid item xs={12} md={6}>
          {loadingGiveaways ? (
            <Skeleton variant="rounded" width="100%" height={270} />
          ) : (
            <GiveawayMain giveaways={giveaways} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {loadingGiveaways ? (
            <Grid container spacing={2}>
              {Array.from(new Array(4)).map((_, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Skeleton variant="rounded" width="100%" height={120} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <GiveawayList giveaways={giveaways} />
          )}
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        {loadingEvents ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Featured events
          </Typography>
        )}
        {loadingEvents ? (
          <Box display="flex" alignItems="center">
            <Skeleton variant="rounded" width={60} height={30} sx={{ mr: 1 }} />
            <Skeleton variant="rounded" width={60} height={30} />
          </Box>
        ) : (
          <Box>
            <IconButton onClick={() => scroll('left')} sx={{ borderRadius: '2px', px: 2.5, py: 0.5, backgroundColor: '#fff', border: '1px solid #ccc' }}>
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => scroll('right')} sx={{ borderRadius: '2px', px: 2.5, py: 0.5, backgroundColor: '#000', border: '1px solid #000', marginLeft: '8px', '&:hover': { backgroundColor: '#333', borderColor: '#333' } }}>
              <ArrowForwardIosIcon fontSize="small" sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        )}
      </Box>

      {loadingEvents ? (
        <Grid container spacing={2}>
          {Array.from(new Array(3)).map((_, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Skeleton variant="rounded" width="100%" height={200} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EventItemsList containerRef={containerRef} events={events} />
      )}
    </Container>
  );
};

export default GiveawaysPage;
