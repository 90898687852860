import React from 'react';
import { Card, CardMedia, CardContent, Typography,CardActionArea } from '@mui/material';

interface GiveawayItemProps {
  image: string;
  title: string;
}

const GiveawayItem: React.FC<GiveawayItemProps> = ({ image, title }) => {
  return (
    <Card sx={{ boxShadow: 'none' }}>
        <CardActionArea>
            <CardMedia component="img" image={image} alt={title} sx={{ borderRadius: 1 }}/>
            <CardContent sx={{padding:'10px 3px'}}>
                <Typography variant="body2" >{title}</Typography>
            </CardContent>
        </CardActionArea>
    </Card>
  );
};

export default GiveawayItem;
