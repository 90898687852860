import React, { useState, useRef } from 'react';
import { Box, Grid, Typography, Skeleton } from '@mui/material';
import GiveawayItem from '../CompItems/GiveawayItem';
import { colors } from '../config/theme';
import { styled } from '@mui/system';

const SeeMore = styled(Typography)({
  color: colors.brandGray,
  cursor: 'pointer',
  borderBottom: '1px solid',
  marginBottom: '8px',
  paddingBottom: '3px',
  width: 'max-content',
  fontSize: '14px',
});

const StyledBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});

const GiveawayContainer = styled(Box)({
  overflowY: 'auto', // Always scrollable
});

interface Giveaway {
  id: number;
  image: string;
  title: string;
  link: string;
}

interface GiveawayListProps {
  giveaways: Giveaway[];
  loading?: boolean; // Add the loading prop here
}

const GiveawayList: React.FC<GiveawayListProps> = ({ giveaways, loading = false }) => {
  const [showAll, setShowAll] = useState(false);
  const [containerHeight, setContainerHeight] = useState<string | number>('34vh');
  const itemRef = useRef<HTMLDivElement>(null);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height={150} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={150} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={150} />
      </>
    );
  }

  return (
    <Box>
      <StyledBox>
        <Typography variant="body1" sx={{ fontWeight: '600', marginBottom: '8px' }}>
          Sweepstakes for this week
        </Typography>
        <SeeMore onClick={handleShowAll}>
          {showAll ? 'Show Less' : 'See All'}
        </SeeMore>
      </StyledBox>
      <GiveawayContainer sx={{ height: containerHeight }}>
        <Grid container spacing={1}>
          {(showAll ? giveaways : giveaways.slice(0, 4)).map((giveaway, index) => (
            <Grid item xs={12} sm={6} key={index} ref={index === 0 ? itemRef : null}>
              <a href={giveaway.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <GiveawayItem image={giveaway.image} title={giveaway.title} />
              </a>
            </Grid>
          ))}
        </Grid>
      </GiveawayContainer>
    </Box>
  );
};

export default GiveawayList;
