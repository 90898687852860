import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Avatar,
  AvatarGroup,
  Grid,
  TextField,
  Badge,
} from '@mui/material'
import { colors } from '../config/theme'
import SearchBar from '../components/searchBar'
import { styled } from '@mui/system'
import { Chat, Message, Profile } from 'src/types/models'
import { Link } from 'react-router-dom'
import ImageViewer from 'react-simple-image-viewer'

const StyledBox = styled(Box)({})

const MessagesHelper: React.FC<{
  chat?: Chat
  users: Profile[]
  myId: number
}> = ({ chat, myId, users }) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [participants, setParticipants] = React.useState<Profile[]>([])
  console.log('chat', chat)
  useEffect(() => {
    if (chat) {
      const participants = users.filter((user) =>
        chat.participants.includes(user.id)
      )
      setParticipants(participants)
    }
  }, [chat, users])
  const getAvatar = (chat: Chat) => {
    const id = chat.participants.find((p) => p !== myId)
    const user = users.find((u) => u.id === id)
    if (chat.avatar) {
      return chat.avatar
    } else if (user?.profile_pic && chat.participants.length === 2) {
      return user.profile_pic
    }
    return ''
  }
  const getAvatarName = (chat: Chat) => {
    if (chat.chat_name) {
      return (chat.chat_name[0] + chat.chat_name[1]).toUpperCase()
    }
    const id = chat.participants.find((p) => p !== myId)
    const user = users.find((u) => u.id === id)
    return user ? `${user.first_name[0]}${user.last_name[0]}`.toUpperCase() : ''
  }
  const getChatName = (chat: Chat) => {
    const id = chat.participants.find((p) => p !== myId)
    const user = users.find((u) => u.id === id)
    if (chat.chat_name) {
      return chat.chat_name
    } else if (user?.first_name && user?.last_name) {
      return `${user.first_name} ${user.last_name}`
    }
    return ''
  }
  const isImage = (url: string) => {
    const imageExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tiff',
      'svg',
      'webp',
      'heic',
      'heif',
      'ico',
      'jfif',
    ]
    const extension = url.split('.').pop()?.toLowerCase() || ''
    return imageExtensions.includes(extension)
  }
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('Search Term:', searchTerm)
  }
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  return (
    <Box
      width="20vw"
      bgcolor={colors.brandPrimary}
      sx={{ overflowY: 'hidden' }}
    >
      <Box
        p={2}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'96%'}
      >
        <StyledBox>
          <Typography variant="h6" mb={2}>
            Chat info
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            rowGap={1}
            mb={2}
          >
            {chat && getAvatar(chat) ? (
              <Avatar src={getAvatar(chat)} sx={{ width: 60, height: 60 }} />
            ) : (
              chat && (
                <Avatar sx={{ width: 60, height: 60 }}>
                  {getAvatarName(chat)}
                </Avatar>
              )
            )}

            {/* <AvatarGroup max={3}>
              <Avatar sx={{ width: '55px', height: '55px' }}>
                {chat.avatar}
              </Avatar>
              {chat.secondaryAvatar && <Avatar src={chat.secondaryAvatar} />}
            </AvatarGroup> */}
            {chat && (
              <Typography variant="body1">{getChatName(chat)}</Typography>
            )}
          </Box>
          {chat && chat.chat_name && (
            <>
              <Typography variant="h6" mb={2} mt={2}>
                Active users
              </Typography>
              <Grid container spacing={1} mb={2}>
                {chat &&
                  chat.chat_name &&
                  participants?.map((user: Profile, index: number) => (
                    <Grid item key={index}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot"
                      >
                        <Avatar src={user.profile_pic} />
                      </Badge>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            onSearchSubmit={handleSearchSubmit}
            backgroundColor={colors.brandBlueBG}
          />
        </StyledBox>
        <StyledBox>
          <Typography variant="h6" mb={2}>
            Recently shared
          </Typography>
          <Grid container spacing={1}>
            {chat &&
              chat.messages?.map((item: Message, index: number) => {
                if (item.attachment) {
                  return (
                    <Grid item key={index} xs={6}>
                      {isImage(String(item.attachment)) ? (
                        <>
                          <img
                            src={String(item.attachment)}
                            onClick={() => openImageViewer(0)}
                            alt={item.text}
                            style={{ maxWidth: '100%', maxHeight: 200 }}
                          />
                          {isViewerOpen && (
                            <ImageViewer
                              src={[String(item.attachment)]}
                              currentIndex={currentImage}
                              disableScroll={false}
                              closeOnClickOutside={true}
                              onClose={closeImageViewer}
                            />
                          )}
                        </>
                      ) : (
                        <Link
                          to={String(item.attachment)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {(item.attachment as unknown as string)
                            .split('/')
                            .pop()}
                        </Link>
                      )}
                    </Grid>
                  )
                }
                return null
              })}
          </Grid>
        </StyledBox>
      </Box>
    </Box>
  )
}

export default MessagesHelper
