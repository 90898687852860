import { useState } from 'react'

export const useMyLocation = () => {
  const [loading, setLoading] = useState(true)
  const [latitude, setLatitude] = useState<number | null>(null)
  const [longitude, setLongitude] = useState<number | null>(null)

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        setLatitude(latitude)
        setLongitude(longitude)
        setLoading(false)
      },
      (error) => {
        console.error('Error getting location:', error)
        setLoading(false)
      }
    )
  } else {
    console.error('Geolocation is not supported by this browser.')
  }
  return { loading, latitude, longitude }
}
