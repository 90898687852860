import React, { useEffect, useRef, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Skeleton ,CardActionArea} from '@mui/material';
import { styled } from '@mui/system';

interface Giveaway {
  id: number;
  image: string;
  title: string;
  link: string;
}

interface GiveawayMainProps {
  giveaways: Giveaway[];
  loading?: boolean; // Add the loading prop here
}

const StyledCardActionArea = styled(CardActionArea)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
});

const GiveawayMain: React.FC<GiveawayMainProps> = ({ giveaways, loading = false }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('auto');

  useEffect(() => {
    if (cardRef.current) {
      setHeight(`100%`);
    }
  }, []);

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={400} />;
  }

  if (giveaways.length === 0) {
    return <Typography>No Giveaways Available</Typography>;
  }

  const mainGiveaway = giveaways[0];

  return (
    <Card
      ref={cardRef}
      sx={{
        boxShadow: 'none',
        height: '100%',
        maxHeight: height,
        overflowY: 'auto'
      }}
    >
      <a href={mainGiveaway.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
        <StyledCardActionArea>
          <CardMedia
            component="img"
            image={mainGiveaway.image}
            alt={mainGiveaway.title}
            sx={{ borderRadius: 1, flexGrow: 1 }}
          />
          <CardContent sx={{ flexShrink: 0, padding: '10px 8px' }}>
            <Typography variant="h6">{mainGiveaway.title}</Typography>
          </CardContent>
        </StyledCardActionArea>
      </a>
    </Card>
  );
};

export default GiveawayMain;
