import { useState, useEffect } from 'react'
import axios, { isAxiosError } from 'axios'
import axiosInstance from '../api/axios' // Use axiosInstance from your configured axios instance file

interface Event {
  id: number
  image: string
  title: string
  date: string
  location: string
  price: string
  link: string
}

const useEvents = () => {
  const [events, setEvents] = useState<Event[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get(
          'https://live-free-life-nation-back.up.railway.app/api/events/'
        )
        setEvents(response.data)
      } catch (err: any) {
        if (isAxiosError(err)) {
          setError(err.response?.data?.detail || err.message)
        } else {
          setError('An unexpected error occurred')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchEvents()
  }, [])

  return { events, loading, error }
}

export default useEvents
