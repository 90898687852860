import { Message } from "src/types/models";

export const createWebSocketConnection = (roomName: string) => {
  const socket = new WebSocket(
    `wss://free-life-nation-back.up.railway.app/ws/chat/${roomName}/`,
  );

  socket.onopen = () => {
    console.log("WebSocket connection opened");
  };

  socket.onclose = (event) => {
    console.log("WebSocket connection closed", event);
  };

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  socket.onmessage = (event) => {
    if (typeof event.data === "string") {
      const data = JSON.parse(event.data);
      console.log("WebSocket message received:", data);
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        console.log("Binary message received:", reader.result);
      };
      reader.readAsArrayBuffer(event.data);
    }
  };

  return socket;
};

export const sendMessage = (socket: WebSocket, message: Message) => {
  const payload = JSON.stringify({ message });
  socket.send(payload);
};
