import { useState, useEffect } from 'react'
import axios, { isAxiosError } from 'axios'
import axiosInstance from '../api/axios'

interface Giveaway {
  id: number
  image: string
  title: string
  link: string
}

const useGiveaways = () => {
  const [giveaways, setGiveaways] = useState<Giveaway[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchGiveaways = async () => {
      try {
        const response = await axiosInstance.get(
          'https://live-free-life-nation-back.up.railway.app/api/giveaways/'
        )
        setGiveaways(response.data)
      } catch (err: any) {
        if (isAxiosError(err)) {
          setError(err.response?.data?.detail || err.message)
        } else {
          setError('An unexpected error occurred')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchGiveaways()
  }, [])

  return { giveaways, loading, error }
}

export default useGiveaways
