import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { colors } from "../../config/theme";
import imageCompression from "browser-image-compression";
import {
  fetchUserId,
  fetchProfile,
  fetchUserData,
  updateStory,
  deleteStory,
  fetchUserStory,
} from "../../api/apiService";
import CustomButton from "../../components/CustomButton";

interface Socials {
  instagram: string;
  facebook: string;
}

interface ProfileProps {
  loading: boolean;
  onLoadingComplete: () => void;
}

interface ProfileData {
  profilePic: string | undefined;
  name: string;
  email: string;
  bio: string;
  socials: Socials;
}

const Profile: React.FC<ProfileProps> = ({ loading, onLoadingComplete }) => {
  const [profile, setProfile] = useState<ProfileData>({
    profilePic: undefined,
    name: "",
    email: "",
    bio: "",
    socials: {
      instagram: "",
      facebook: "",
    },
  });
  const [hasActiveStory, setHasActiveStory] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // success | error
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const userId = await fetchUserId();
        const userData = await fetchUserData();
        const userProfile = await fetchProfile(userId);
        const { first_name, username, profile_pic, bio } = userProfile;

        setProfile({
          profilePic: profile_pic || getInitialsAvatar(first_name, username),
          name: `${userData.first_name} ${userData.last_name}`,
          email: userData.email,
          bio: bio || "",
          socials: {
            instagram: "https://instagram.com",
            facebook: "https://facebook.com",
          },
        });

        const storyResponse = await fetchUserStory(userId);

        // Updated logic to ensure `hasActiveStory` reflects reality
        setHasActiveStory(!!storyResponse);

        onLoadingComplete();
      } catch (error) {
        console.error("Error fetching profile data:", error);
        onLoadingComplete();
      }
    }

    fetchData();
  }, [onLoadingComplete]);

  const getInitialsAvatar = (firstName: string, username: string): string => {
    const initials =
      firstName.charAt(0).toUpperCase() + username.charAt(0).toUpperCase();
    return initials;
  };

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Image compression error:", error);
      return file;
    }
  };

  const handleProfilePicChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const compressedFile = await compressImage(file);
        setSelectedFile(compressedFile);
        setOpen(true);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const handleConfirm = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      try {
        const userId = await fetchUserId();
        formData.append("user", userId.toString());

        await updateStory(userId, formData);

        const userProfile = await fetchProfile(userId);
        setProfile((prevProfile) => ({
          ...prevProfile,
          profilePic: userProfile.profile_pic,
        }));

        setHasActiveStory(true);
        setSnackbar({
          open: true,
          message: "Story uploaded successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating story:", error);
        setSnackbar({
          open: true,
          message: "Failed to upload story.",
          severity: "error",
        });
      } finally {
        setOpen(false);
        setSelectedFile(null);
      }
    }
  };

  const handleDeleteStory = async () => {
    try {
      await deleteStory();
      setHasActiveStory(false);
      setSnackbar({
        open: true,
        message: "Story deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting story:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete story.",
        severity: "error",
      });
    }
  };

  const closeSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box display="flex" alignItems="start" justifyContent="space-between" mt={4}>
      <Box display="flex" alignItems="start" >
        <Box position="relative" display="inline-block">
          {loading ? (
            <Skeleton variant="rounded" width={120} height={120} />
          ) : (
            <Avatar
              src={profile.profilePic}
              sx={{ width: 120, height: 120, borderRadius: "7px" }}
            />
          )}
          {!hasActiveStory && !loading && (
            <Box
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              height="70%"
              display="flex"
              borderRadius="0 0 4px 7px"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                cursor: "pointer",
                transition: "opacity 0.3s",
                opacity: 1,
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)",
                "&:hover": {
                  opacity: 0.7,
                },
              }}
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleProfilePicChange}
                />
                <AddIcon fontSize="large" sx={{ color: "white" }} />
              </IconButton>
              <Typography variant="caption" color="white" fontSize="14px">
                Add Story
              </Typography>
            </Box>
          )}
        </Box>
        <Box ml={2}>
          {loading ? (
            <>
              <Skeleton variant="text" width={150} height={45} />
              <Skeleton
                variant="text"
                width={200}
                height={35}
                sx={{ mt: 0.5 }}
              />
              <Skeleton variant="text" width={250} height={30} sx={{ mt: 0.5 }} />
            </>
          ) : (
            <>
              <Typography variant="h6">{profile.name}</Typography>
              <Typography variant="body1" color={colors.brandGray50} mt={0.5}>
                {profile.email}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={0.5}>
                {profile.bio}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {!loading && hasActiveStory && (
        <CustomButton
          variant="contained"
          onClick={handleDeleteStory}
          sx={{
            backgroundColor:colors.brandSecondary,
            display: { sm: "flex", xs: "none", md: "flex" },
            fontSize: "12px",
            padding: "6px 12px", // Explicitly set padding
            ml: 2,
            width: "auto", // Adjust width to fit the content
            whiteSpace: "nowrap", // Prevent text wrapping
            textTransform: "none", // Keep the text as is (no uppercase transformation)
            minWidth: "fit-content", // Ensure the button respects padding and text size
          }}
        >
          Delete Story
        </CustomButton>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Story</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to upload this story?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "gray" }}>
            Cancel
          </Button>
          <CustomButton onClick={handleConfirm} variant="contained" color="primary">
            Confirm
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor:
              snackbar.severity === "success" ? colors.brandGreen : colors.brandRed,
          },
        }}
      />
    </Box>
  );
};

export default Profile;
