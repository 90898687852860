import React, { useState ,useEffect} from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import Stories from '../components/feed/Stories';
import Suggestions from '../components/feed/Suggestions';
import Featured from '../components/feed/Featured';

const FeedHelper: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [storiesLoaded, setStoriesLoaded] = useState(false);
  const [suggestionsLoaded, setSuggestionsLoaded] = useState(false);
  const [featuredLoaded, setFeaturedLoaded] = useState(false);

  const handleStoriesLoadingComplete = () => setStoriesLoaded(true);
  const handleSuggestionsLoadingComplete = () => setSuggestionsLoaded(true);
  const handleFeaturedLoadingComplete = () => setFeaturedLoaded(true);

  useEffect(() => {
    if (storiesLoaded && suggestionsLoaded && featuredLoaded) {
      setLoading(false);
    }
  }, [storiesLoaded, suggestionsLoaded, featuredLoaded]);

  return (
    <Box
      padding="20px"
      display="flex"
      justifyContent="space-around"
      flexDirection="column"
      height="96%"
    >
      <Box marginBottom="10px">
        {loading ? (
          <Skeleton variant="text" width={100} height={40} />
        ) : (
          <Typography variant="h6" sx={{ fontWeight: '600' }}>
            Stories
          </Typography>
        )}
        <Stories loading={loading} onLoadingComplete={handleStoriesLoadingComplete} />
      </Box>
      <Box marginBottom="0px">
        {loading ? (
          <Skeleton variant="text" width={100} height={40} sx={{ mb: 0.5 }} />
        ) : (
          <Typography variant="h6" sx={{ fontWeight: '600' }} mb={0.5}>
            Suggestions
          </Typography>
        )}
        <Suggestions loading={loading} onLoadingComplete={handleSuggestionsLoadingComplete} suggestionsCount={4} />
      </Box>
      <Box>
        {loading ? (
          <Skeleton variant="text" width={100} height={40} />
        ) : (
          <Typography variant="h6" sx={{ fontWeight: '600' }}>
            Urgent
          </Typography>
        )}
        <Featured loading={loading} onLoadingComplete={handleFeaturedLoadingComplete} />
      </Box>
    </Box>
  );
};

export default FeedHelper;
