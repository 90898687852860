import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Box, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { colors } from '../config/theme';

interface ShopItemProps {
  name: string;
  description: string;
  image: string;
  logo: string;
  link: string;
  mapLink: string;
}

const ShopItem: React.FC<ShopItemProps> = ({ name, description, image, logo, link, mapLink }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' ,backgroundColor:colors.brandGrayBGLight ,overflow:'hidden',borderRadius:'5px'}}>
        <CardMedia component="img" height="140" image={image} alt={name} />
        <Box sx={{ textAlign: 'center', marginTop: -3 ,paddingLeft:1}}>
        <CardMedia component="img" image={logo} alt={`${name} logo`} sx={{ width: 45, height: 45, borderRadius: '50%', border: '2px solid #fff' }} />
        </Box>
        <CardContent sx={{ flex: 1 ,backgroundColor:colors.brandGrayBGLight, padding:'0px 10px 10px 10px'}} >
        <Typography gutterBottom variant="h6" component="div">
            {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {description}
        </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 5px 10px 5px', backgroundColor: colors.brandGrayBGLight }}>
            <Link href={mapLink} underline="none" style={{  color: colors.brandGray,textDecoration: 'underline',display: 'flex', alignItems: 'center',fontSize:'12px' }}>
            <LocationOnIcon sx={{ marginRight: '0px' ,color: colors.brandSecondary,height:'18px'}} />
            View in map
            </Link>
            <Link href={link} underline="none" style={{ color: colors.brandBlue, display: 'flex', alignItems: 'center' ,fontSize:'12px'}}>
            Visit Website
            <ArrowOutwardIcon sx={{ marginLeft: '0px' ,height:'18px'}} />
            </Link>
        </Box>
    </Box>
  );
};

export default ShopItem;
