// File: /hoc/ProtectedRoute.tsx

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const PublicRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated && location.pathname === "/home") {
    // Redirect authenticated users from /home to /
    return <Navigate to="/" replace />;
  }

  // Render the child routes if authenticated
  return <Outlet />;
};

export default PublicRoute;
