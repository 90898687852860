import React from 'react'
import { TextField, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { colors } from '../config/theme'

interface SearchBarProps {
  searchTerm: string
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearchSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  backgroundColor?: string
  paddingR?: string
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  onSearchChange,
  onSearchSubmit,
  backgroundColor,
  paddingR,
}) => {
  return (
    <form
      onSubmit={onSearchSubmit}
      style={{ display: 'flex', padding: '0px', paddingLeft: paddingR }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search"
        value={searchTerm}
        onChange={onSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            backgroundColor: backgroundColor,
          },
          '& .MuiOutlinedInput-input': {
            py: '8px',
          },
        }}
      />
    </form>
  )
}

export default SearchBar
