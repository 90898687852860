import React from 'react';
import { Box } from '@mui/material';
import CategoryItem from '../CompItems/CategoryItem';
import home from '../assets/media/homeservice.png';
import events from '../assets/media/events.png';
import food from '../assets/media/food.png';
import healthcare from '../assets/media/healthcare.png';
import clothing from '../assets/media/clothing.png';
import automative from '../assets/media/automative.png';

interface Shop {
  id: number;
  logo: string;
  background_image: string;
  business_name: string;
  website: string;
  location: string;
  description: string;
  category: string;
}

interface CategoryListProps {
  shops: Shop[];
  setFilteredShops: (shops: Shop[]) => void;
}

const categories = [
  { name: 'Home Service', imageUrl: home },
  { name: 'Events & festivals', imageUrl: events },
  { name: 'Food & Catering', imageUrl: food },
  { name: 'Health', imageUrl: healthcare },
  { name: 'Clothing', imageUrl: clothing },
  { name: 'Automotive', imageUrl: automative },
];

const CategoryList: React.FC<CategoryListProps> = ({ shops, setFilteredShops }) => {
  const handleCategoryClick = (category: string) => {
    const filtered = shops.filter(shop => shop.category === category);
    setFilteredShops(filtered);
  };

  const getCategoryCount = (categoryName: string) => {
    return shops.filter(shop => shop.category === categoryName).length;
  };

  return (
    <Box sx={{ display: 'flex', overflowX: 'auto', padding: '0.5rem 0' }}>
      {categories.map((category) => (
        <CategoryItem
          key={category.name}
          name={category.name}
          count={getCategoryCount(category.name)}
          imageUrl={category.imageUrl}
          onClick={() => handleCategoryClick(category.name)}
        />
      ))}
    </Box>
  );
};

export default CategoryList;
