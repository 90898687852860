import { useEffect, useState } from "react";
import { fetchUserId, updateProfile } from "src/api/apiService";
import { useMyLocation } from "./useMyLocation";

const useUpdateLocation = () => {
  const [loading, setLoading] = useState(true);
  const { latitude, longitude, loading: locationLoading } = useMyLocation();
  console.log("latitude:", latitude);
  useEffect(() => {
    const updateLocation = async (latitude: number, longitude: number) => {
      try {
        const userId = await fetchUserId();
        const formData = new FormData();
        formData.append("latitude", latitude.toFixed(6));
        formData.append("longitude", longitude.toFixed(6));
        await updateProfile(userId, formData);
      } catch (error) {
        console.error("Error updating location:", error);
      }
    };
    if (latitude && longitude) {
      updateLocation(latitude, longitude).finally(() => setLoading(false));
    } else if (!locationLoading) {
      console.error("Unable to get location.");
      setLoading(false);
    }
    setLoading(false);
  }, [latitude, longitude, locationLoading]);

  return { loading: loading || locationLoading };
};

export default useUpdateLocation;
