import { useState, useEffect } from 'react'
import axios, { isAxiosError } from 'axios'
import axiosInstance from '../api/axios' // Use axiosInstance from your configured axios instance file

interface Shop {
  id: number
  logo: string
  background_image: string
  business_name: string
  website: string
  location: string
  description: string
  category: string
}

const useShops = () => {
  const [shops, setShops] = useState<Shop[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const token = localStorage.getItem('token') // Retrieve the token from localStorage
        const response = await axiosInstance.get(
          'https://live-free-life-nation-back.up.railway.app/api/shops/',
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          }
        )
        setShops(response.data)
      } catch (err: any) {
        if (isAxiosError(err)) {
          setError(err.response?.data?.detail || err.message)
        } else {
          setError('An unexpected error occurred')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchShops()
  }, [])

  return { shops, loading, error }
}

export default useShops
