import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Container } from '@mui/material';
import UserDetailsForm from '../components/sett/UserDetailsForm';
import BusinessForm from '../components/sett/BusinessForm';
import AccountForm from '../components/sett/AccountForm';
import { colors } from '../config/theme';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}> {/* Remove padding by setting p: 0 */}
          {children}
        </Box>
      )}
    </div>
  );
}

function SettingsPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ mt: 3, padding: '20px' }} disableGutters>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }} pb={2}>Settings</Typography>
      <Tabs 
        value={value} 
        onChange={handleChange} 
        sx={{
          mb: '-1px',
          pb: 0,
          '& .MuiTab-root': { 
            padding: '0px 12px', 
            minWidth: 'auto', 
            zIndex: 1, 
            '&.Mui-selected': { color: colors.brandSecondary },
          },
          '& .MuiTabs-indicator': { 
            backgroundColor: colors.brandSecondary, 
            zIndex: 3, 
            marginBottom: '-1px' 
          },
        }}
      >
        <Tab label="User details" sx={{ fontSize: '0.8rem' }} />
        <Tab label="Membership" sx={{ fontSize: '0.8rem' }} />
        <Tab label="Account" sx={{ fontSize: '0.8rem' }} />
        <Tab label="Business" sx={{ fontSize: '0.8rem' }} />
      </Tabs>
      <Box sx={{ borderBottom: '1px solid #ddd', zIndex: 1 }}></Box>
      <TabPanel value={value} index={0}>
        <UserDetailsForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{mt:'20%',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Typography variant="h6"  align="center">Coming soon</Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AccountForm />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BusinessForm />
      </TabPanel>
    </Container>
  );
}

export default SettingsPage;
