import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { SxProps } from '@mui/system';

interface CustomButtonProps extends ButtonProps {
  variantType?: 'contained' | 'outlined';
  sx?: SxProps;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, variantType = 'contained', sx, ...props }) => (
  <Button
    variant={variantType}
    size="small"
    sx={{
      fontSize: '0.7rem',
      padding: '7px 14px',
      boxShadow: 'none',
      borderRadius: '4px',
      ...(variantType === 'contained'
        ? {
            backgroundColor: 'black',
            color: 'white',
            '&:hover': {
              backgroundColor: 'black',
              opacity: 0.8,
            },
          }
        : {
            borderColor: 'black',
            color: 'black',
            backgroundColor: 'white',
            '&:hover': {
              borderColor: 'black',
              backgroundColor: 'white',
              opacity: 0.8,
            },
          }),
      ...sx, // Merge with the provided sx prop
    }}
    {...props}
  >
    {children}
  </Button>
);

export default CustomButton;
