import { useEffect, useState } from 'react'
import { fetchUserId, fetchProfile } from '../api/apiService'
import { Profile } from '../types/models'

const useFetchUserFriends = () => {
  const [userId, setUserId] = useState<number | null>(null)
  const [friends, setFriends] = useState<Profile[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchCurrentUserFriends = async () => {
      try {
        const id = await fetchUserId()
        setUserId(id)

        const profile = await fetchProfile(id)
        const friendsProfiles = await Promise.all(
          profile.friends.map((friendId: number) => fetchProfile(friendId))
        )

        setFriends(friendsProfiles)
      } catch (error) {
        console.error('Error fetching user friends:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchCurrentUserFriends()
  }, [])

  return { friends, loading }
}

export default useFetchUserFriends
