import React, { useState } from 'react'
import {
  Avatar,
  Typography,
  Box,
  Card,
  CardMedia,
  Modal,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import storyPlaceholder from '../../assets/media/story_placeholder.png'

interface StoryProps {
  avatar?: string
  name: string
  media: string // URL to the media (image or video thumbnail)
  mediaType: 'image' | 'video'
  hasStory: boolean
}

const Story: React.FC<StoryProps> = ({
  avatar,
  name,
  media,
  mediaType,
  hasStory,
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    if (!hasStory) return
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Display only first name if name is too long
  const displayName = name.length > 8 ? `${name.substring(0, 8)}...` : name

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="0 4px"
      >
        <Card
          sx={{
            position: 'relative',
            width: 110,
            height: 160,
            overflow: 'hidden',
            borderRadius: '5px',
            cursor: 'pointer',
            border: '1px solid white',
            boxShadow: 'none',
            opacity: hasStory ? 1 : 0.8,
          }}
          onClick={handleOpen}
        >
          {media ? (
            <CardMedia
              component="img"
              image={media}
              alt={name}
              sx={{ height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <CardMedia
              component="img"
              image={storyPlaceholder}
              alt={name}
              sx={{ height: '100%', objectFit: 'cover' }}
            />
          )}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              color: 'white',
              padding: '8px 8px',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {avatar ? (
              <Avatar src={avatar} sx={{ mr: 1, width: 32, height: 32 }} />
            ) : (
              <Avatar sx={{ mr: 1, width: 32, height: 32 }}>{name[0]}</Avatar>
            )}
            <Typography variant="body1" sx={{ fontSize: '0.8rem' }}>
              {displayName}
            </Typography>
          </Box>
        </Card>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '80%',
            maxWidth: 600,
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            overflow: 'auto',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 10, right: 10 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {mediaType === 'image' ? (
            <img
              src={media}
              alt={name}
              style={{ width: '100%', height: 'auto', maxHeight: '90vh' }}
            />
          ) : (
            <video
              controls
              style={{ width: '100%', height: 'auto', maxHeight: '90vh' }}
            >
              <source src={media} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default Story
