import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import ShopItem from '../CompItems/ShopItem';

interface Shop {
  id: number;
  logo: string;
  background_image: string;
  business_name: string;
  website: string;
  location: string;
  description: string;
  category: string;
}

interface ShopItemListProps {
  shops: Shop[];
  loading?: boolean;
}

const ShopItemList: React.FC<ShopItemListProps> = ({ shops, loading = false }) => {
  return (
    <Grid container spacing={2}>
      {loading ? (
        Array.from(new Array(6)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex' }}>
            <Skeleton variant="rounded" width="100%" height={200} />
          </Grid>
        ))
      ) : (
        shops.map((shop) => (
          <Grid item xs={12} sm={6} md={4} key={shop.id} style={{ display: 'flex' }}>
            <ShopItem
              name={shop.business_name}
              description={shop.description}
              image={shop.background_image}
              logo={shop.logo}
              link={shop.website}
              mapLink={`https://maps.google.com/?q=${shop.location}`}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default ShopItemList;
