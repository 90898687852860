import React from 'react';
import { Card, CardActionArea, Typography, Box } from '@mui/material';
import { colors } from '../config/theme';

interface CategoryItemProps {
  name: string;
  count: number;
  imageUrl: string;
  onClick: () => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ name, count, imageUrl, onClick }) => {
  return (
    <Box sx={{ position: 'relative', width: 120, height: 120, marginRight: 2, flexShrink: 0 }}>
      <Card sx={{ height: '100%', width: '100%', boxShadow: 'none' }}>
        <CardActionArea onClick={onClick} sx={{ height: '100%', width: '100%', position: 'relative' }}>
          <Box
            component="img"
            src={imageUrl}
            alt={name}
            sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
              color: '#fff',
              textAlign: 'start',
              padding: '8px 8px',
            }}
          >
            <Typography variant="h6" sx={{ fontSize: '11px' }}>{name}</Typography>
            <Typography variant="body2" sx={{ backgroundColor: 'rgba(254, 91, 72, 0.5)', width: 'max-content', padding: '3px 7px', fontSize: '10px', borderRadius: '100px' }}>{count} Listings</Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default CategoryItem;
