import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@mui/material'
import { styled, useTheme } from '@mui/system'
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import BackgroundImage from '../assets/media/community.png'
import Image1 from '../assets/media/welcome1.jpg'
import Image2 from '../assets/media/welcome2.jpg'
import mission from '../assets/media/mission.jpg'
import faq from '../assets/media/faq.jpg'
import logo from '../assets/media/mainlogo.svg'
import CustomButton from '../components/CustomButton'
import { colors } from '../config/theme'
import { Icons } from '../assets/index'
import Footer from '../components/Footer'
import FormDialog from '../components/landing/FormDialog' // Import the FormDialog component
import MenuIcon from '@mui/icons-material/Menu'
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material'
import useAuth from "../hooks/useAuth";

const Root = styled('div')({
  flexGrow: 1,
})

const HeroContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0, 6),
  backgroundColor: theme.palette.primary.main,
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '8px',
  color: 'black',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(4, 0, 6),
  },
}))

const HeroButtons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0, 6),
  },
}))

const Image = styled('img')({
  width: '100%',
  borderRadius: '8px',
})

const LogoImage = styled('img')(({ theme }) => ({
  height: '80px',
  marginRight: theme.spacing(2),
}))

const NavLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))
const AboutPlatformSection = styled('div')(({ theme }) => ({
  width: '93%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  padding: '24px',
  marginTop: '40px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
  },
}))

const AboutImages = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginRight: '20px',
  columnGap: '15px',
  width: '40%',
  '& img': {
    margin: '12px 0',
    borderRadius: '8px',
    width: '45%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '65%',
    justifyContent: 'center',
    '& img': {
      margin: '12px 0',
      borderRadius: '8px',
      width: '60%',
    },
  },
}))

const OurMission = styled('div')(({ theme }) => ({
  width: '93%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left',
  padding: '24px',
  marginTop: '40px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
}))

const MissionImages = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginRight: '20px',
  columnGap: '15px',
  width: '40%',
  '& img': {
    margin: '10px 0',
    borderRadius: '8px',
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    '& img': {
      margin: '12px 0',
      borderRadius: '8px',
      width: '100%',
    },
  },
}))

const AboutText = styled(Box)(({ theme }) => ({
  flex: 1,
  width: '60%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start', // Default alignment
  width: '100%',
  marginTop: '70px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
    margin: '10px',
  },
}))

const FaqImg = styled('img')(({ theme }) => ({
  width: '36%',
  height: '36%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const FAQSection = styled(Container)(({ theme }) => ({
  padding: '20px 0px',
  textAlign: 'left',
  width: '64%',
  marginRight: '20px',
  marginLeft: '10px',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const FAQItem = styled(Accordion)(({ theme }) => ({
  margin: '10px 0',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}))

const FAQHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '20px',
}))

const LandingPage: React.FC = () => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<string | false>(false)
  const [open, setOpen] = useState(false)
  const [isSignIn, setIsSignIn] = useState(true)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { isAuthenticated } = useAuth();

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open)
  }

  const renderDrawer = (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <List>
        <ListItem button component={Link} to="#" onClick={toggleDrawer(false)}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="#" onClick={toggleDrawer(false)}>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component={Link} to="#" onClick={toggleDrawer(false)}>
          <ListItemText primary="Contact" />
        </ListItem>
        {!isAuthenticated ? (
        <>
          <ListItem button onClick={() => handleClickOpen(true)} sx={{ mr: 2 }}>
            <ListItemText primary="Sign In" />
          </ListItem>
          <ListItem>
            <CustomButton color="primary" onClick={() => handleClickOpen(false)}>
              Sign Up
            </CustomButton>
          </ListItem>
        </>
      ) : (
        <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
          <ListItemText primary="Dashboard" />
        </ListItem>
      )}
      </List>
    </Drawer>
  )

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const handleClickOpen = (signIn: boolean) => {
    setIsSignIn(signIn)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const toggleForm = () => {
    setIsSignIn(!isSignIn)
  }

  return (
    <Root>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ backgroundColor: 'white', width: '97%' }}
        >
          <Toolbar>
            <LogoImage src={logo} alt="Logo" theme={theme} />
            {isMobile ? (
              <>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ ml: 'auto' }}
                >
                  <MenuIcon />
                </IconButton>
                {renderDrawer}
              </>
            ) : (
              <>
                <NavLinks theme={theme}>
                  <Button color="inherit" component={Link} to="#">
                    Home
                  </Button>
                  <Button color="inherit" component="a" href="#about">
                    About
                  </Button>
                  <Button color="inherit" component="a" href="#bottom">
                    Contact
                  </Button>
                </NavLinks>
                {!isAuthenticated ? (
                <>
                  <Button color="inherit" onClick={() => handleClickOpen(true)} sx={{ mr: 2 }}>
                    Sign In
                  </Button>
                  <CustomButton color="primary" onClick={() => handleClickOpen(false)}>
                    Sign Up
                  </CustomButton>
                </>
              ) : (
                <Button color="primary" component={Link} to="/">
                  Dashboard
                </Button>
              )}
              </>
            )}
          </Toolbar>
        </AppBar>
        <Box sx={{ backgroundColor: 'white', width: '97%', mt: 1 }}>
          <Container maxWidth={false}>
            <HeroContent theme={theme} sx={{ p: 5 }}>
              <Box sx={{ width: { md: '64%', xs: '90%' } }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    fontWeight: '600',
                    fontSize: { sm: 'h3.fontSize' },
                  }}
                  my={2}
                >
                  Empower Your Community, Lower Your Costs.
                </Typography>
                <Typography
                  variant="h6"
                  paragraph
                  color={colors.brandGray}
                  sx={{ fontWeight: '500' }}
                  mb={3}
                >
                  Strengthening Communities, Lowering Expenses: Connect Locally,
                  Save Globally, and Rediscover the Power of Shared Resources
                  and Mutual Support.
                </Typography>
                <HeroButtons theme={theme}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <CustomButton
                        variant="contained"
                        color="primary"
                        component={Link}
                        size="large"
                        sx={{ fontSize: '14px', padding: '8px 15px' }}
                        onClick={() => handleClickOpen(false)}
                      >
                        Get Started!
                      </CustomButton>
                    </Grid>
                  </Grid>
                </HeroButtons>
              </Box>
              <Box sx={{ mx: '24px', width: { md: '36%' } }}>
                <Image src={BackgroundImage} alt="Community" />
              </Box>
            </HeroContent>
          </Container>
        </Box>
        <AboutPlatformSection theme={theme}>
          <AboutImages>
            <img src={Image1} alt="Image 1" />
            <img src={Image2} alt="Image 2" />
          </AboutImages>
          <AboutText>
            <Typography
              variant="subtitle1"
              color={theme.palette.secondary.main}
              gutterBottom
              id='about'
            >
              About our Platform
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: '600' }}
              mb={2}
            >
              Welcome to Free Life Nation: Building Communities!
            </Typography>
            <Typography variant="h6" color={colors.brandGray} paragraph>
              Free Life Nation: Empowering Communities to Thrive Together. Join
              us to revolutionize neighbor interactions and build resilient,
              vibrant societies. Welcome to your community gateway for stronger
              bonds and smarter savings.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: '600',
                    alignItems: 'center',
                    display: 'flex',
                    columnGap: '10px',
                  }}
                >
                  <Icons.checkIcon size={30} /> Save Money
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: '600',
                    alignItems: 'center',
                    display: 'flex',
                    columnGap: '10px',
                  }}
                >
                  <Icons.checkIcon size={30} /> Build Relationships
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: '600',
                    alignItems: 'center',
                    display: 'flex',
                    columnGap: '10px',
                  }}
                >
                  <Icons.checkIcon size={30} /> Foster Community
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: '600',
                    alignItems: 'center',
                    display: 'flex',
                    columnGap: '10px',
                  }}
                >
                  <Icons.checkIcon size={30} /> Enhance Quality of Life
                </Typography>
              </Grid>
            </Grid>
          </AboutText>
        </AboutPlatformSection>
        <OurMission theme={theme}>
          <AboutText>
            <Typography
              variant="subtitle1"
              color={theme.palette.secondary.main}
              gutterBottom
              id='mission'
            >
              Our Mission
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: '600' }}
              mb={2}
            >
              Elevating Your Living
            </Typography>
            <Typography variant="h6" color={colors.brandGray} paragraph>
              At Free Life Nation, we're dedicated to enhancing your daily life
              through community connections, shared resources, and promoting
              support. Engage with neighbors to access opportunities for a
              better quality of life. Whether you need help with errands, want
              to share surplus goods, or lend a hand, Free Life Nation has you
              covered. Join us in creating a more connected, resilient community
              today.
            </Typography>
            <CustomButton
              variant="contained"
              color="primary"
              component={Link}
              size="large"
              sx={{ fontSize: '12px', padding: '8px 15px' }}
              onClick={() => handleClickOpen(false)}
            >
              Get Started!
            </CustomButton>
          </AboutText>
          <MissionImages>
            <img src={mission} alt="Image 1" />
          </MissionImages>
        </OurMission>
        <StyledBox>
          <FaqImg src={faq} alt="" />
          <FAQSection>
            <Typography
              variant="subtitle1"
              color={theme.palette.secondary.main}
              gutterBottom
              id='faq'
            >
              FAQ
            </Typography>
            <FAQHeading variant="h4">Frequently Asked Questions</FAQHeading>
            <FAQItem
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>How does Free Life Nation work?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Free Life Nation is a platform designed to connect neighbors
                  for mutual support and resource sharing. Users can post
                  requests for help or offer assistance to others in their
                  community.
                </Typography>
              </AccordionDetails>
            </FAQItem>
            <FAQItem
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Is Free Life Nation free to use?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, Free Life Nation is completely free to use. There are no
                  subscription fees or hidden costs. Our goal is to promote
                  community engagement and mutual support without financial
                  barriers.
                </Typography>
              </AccordionDetails>
            </FAQItem>
            <FAQItem
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>
                  How can I ensure my safety when interacting with people?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We recommend following common safety practices such as meeting
                  in public places, informing a friend or family member of your
                  plans, and trusting your instincts. Free Life Nation also
                  provides a rating and review system to help you choose trusted
                  community members to interact with.
                </Typography>
              </AccordionDetails>
            </FAQItem>
            <FAQItem
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography>
                  Can businesses or organizations use Free Life Nation?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, businesses and organizations can use Free Life Nation to
                  connect with the local community, offer services, and
                  participate in community support initiatives.
                </Typography>
              </AccordionDetails>
            </FAQItem>
          </FAQSection>
        </StyledBox>
        <Box
          sx={{
            mt: '70px',
            mb: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: '600', mb: '25px', textAlign: 'center' }}
          >
            Ready to connect with your community?
          </Typography>
          <CustomButton
            variant="contained"
            color="primary"
            component={Link}
            size="medium"
            sx={{ fontSize: '14px', padding: '8px 15px' }}
            id='bottom'
            onClick={() => handleClickOpen(false)}
          >
            Get Started
          </CustomButton>
        </Box>
        <Footer />
      </Box>
      <FormDialog
        open={open}
        handleClose={handleClose}
        isSignIn={isSignIn}
        toggleForm={toggleForm}
        setSignIn={setIsSignIn}
      />
    </Root>
  )
}

export default LandingPage
