import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Box,
  IconButton,
  Button,
  Grid,
  Paper,
  Container,
  Skeleton,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Share } from '@mui/icons-material';
import {
  fetchPostDetails,
  fetchUserById,
  fetchOtherPosts,
} from '../api/apiService'; // Adjust import path as needed
import { Icons } from '../assets/index';
import { format } from 'date-fns';
import useMyProfile from 'src/hooks/useMyProfile';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { enforceHttps } from '../utils/utility';

const PostDetailsPage: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const navigate = useNavigate();
  const { userId } = useMyProfile();
  const [post, setPost] = useState<any>(null); // Adjust the type as per your post structure
  const [similarPosts, setSimilarPosts] = useState<any[]>([]); // Adjust the type as per your post structure
  const [authorNames, setAuthorNames] = useState<{ [key: number]: string }>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!postId) {
          throw new Error('Post ID is undefined or null');
        }
        const postData = await fetchPostDetails(postId);
        postData.images = postData.images
          ? Array.isArray(postData.images)
            ? postData.images
            : [postData.images]
          : [];
        setPost(postData);
      } catch (error) {
        console.error('Error fetching post details:', error);
      }
    };

    fetchPost();
  }, [postId]);

  useEffect(() => {
    const fetchAuthorName = async (authorId: number) => {
      try {
        const author = await fetchUserById(authorId);
        const name =
          author.first_name && author.last_name
            ? `${author.first_name} ${author.last_name}`
            : author.username;
        setAuthorNames((prevNames) => ({ ...prevNames, [authorId]: name }));
      } catch (error) {
        console.error(`Error fetching author details:`, error);
      }
    };

    const fetchSimilarPostsData = async () => {
      try {
        if (post) {
          let similarPostsData = await fetchOtherPosts();
          similarPostsData = similarPostsData.filter(
            (p: any) => p.category === post.category
          );
          setSimilarPosts(similarPostsData.slice(0, 2));
          similarPostsData
            .slice(0, 2)
            .forEach((similarPost: any) => fetchAuthorName(similarPost.author));
        }
      } catch (error) {
        console.error('Error fetching similar posts:', error);
      }
    };

    fetchSimilarPostsData();
  }, [post]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'PPpp');
  };

  const handleShareClick = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (!post) {
    return (
      <Container disableGutters sx={{ padding: 0, margin: 0 }}>
        <Box sx={{ position: 'relative' }}>
          <Skeleton variant="rectangular" width="100%" height={400} />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{
              zIndex: 2,
              borderRadius: '2px',
              position: 'absolute',
              top: 16,
              left: 16,
              backgroundColor: 'rgba(253, 93, 71, 0.7)',
            }}
          />
        </Box>
        <Box px={'20px'}>
          <Card
            sx={{
              mb: 0,
              p: 0,
              boxShadow: 'none',
              borderRadius: 0,
            }}
          >
            <CardContent sx={{ padding: 0 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pt={2}
              >
                <Skeleton variant="text" width="70%" height={30} />
                <Skeleton variant="text" width="30%" height={20} />
              </Box>
              <Skeleton
                variant="text"
                width="100%"
                height={20}
                sx={{ mt: 1 }}
              />
              <Skeleton variant="text" width="90%" height={20} sx={{ mt: 1 }} />
              <Skeleton variant="text" width="80%" height={20} sx={{ mt: 1 }} />
            </CardContent>
          </Card>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Skeleton
              variant="rectangular"
              width={120}
              height={40}
              sx={{ mr: 2 }}
            />
            <Skeleton variant="rectangular" width={120} height={40} />
          </Box>
          <Typography
            variant="h6"
            sx={{ mt: 4, mb: 2, pb: 1, borderBottom: '1px solid #9A9A9A' }}
          >
            Similar posts
          </Typography>
          <Grid container spacing={0.5}>
            {[1, 2].map((_, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box sx={{ cursor: 'pointer' }}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      padding: 2,
                      height: '100%',
                      boxShadow: 'none',
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Skeleton
                        variant="circular"
                        width={40}
                        height={40}
                        sx={{ mr: 2 }}
                      />
                      <Box>
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="60%" height={20} />
                      </Box>
                    </Box>
                    <Skeleton
                      variant="text"
                      width="80%"
                      height={30}
                      sx={{ mt: 0.5 }}
                    />
                    <Skeleton
                      variant="text"
                      width="90%"
                      height={20}
                      sx={{ mt: 0.5, mb: 1 }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={140}
                      sx={{ mt: 1, borderRadius: '4px' }}
                    />
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    );
  }

  return (
    <Container disableGutters sx={{ padding: 0, margin: 0 }}>
      <Box sx={{ position: 'relative' }}>
        {post.images && post.images.length > 0 ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Navigation]}
            pagination={{ clickable: true }}
            style={{ height: 400 }}
          >
            {post.images.map((image: string, index: number) => (
              <SwiperSlide key={index}>
                <CardMedia
                  component="img"
                  height="400"
                  image={enforceHttps(image)}
                  alt={`${post.title} image ${index + 1}`}
                  sx={{ objectFit: 'cover' }}
                />
              </SwiperSlide>
            ))}
            <IconButton
              className="swiper-button-prev"
              sx={{
                position: 'absolute',
                color:'rgb(253, 93, 71)',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%) scale(0.65)',
                zIndex: 10,
              }}
            >
              {/* <ArrowBackIosNewIcon /> */}
            </IconButton>
            <IconButton
              className="swiper-button-next"
              sx={{
                position: 'absolute',
                color:'rgb(253, 93, 71)',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%) scale(0.65)',
                zIndex: 10,
              }}
            >
              {/* <ArrowBackIosNewIcon sx={{ transform: 'rotate(180deg) scale(0)' ,}} /> */}
            </IconButton>
          </Swiper>
        ) : null}
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            zIndex: 2,
            borderRadius: '2px',
            position: 'absolute',
            top: 16,
            left: 16,
            backgroundColor: 'rgba(253, 93, 71, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(253, 93, 71, 1)',
            },
          }}
        >
          <ArrowBackIosNewIcon
            sx={{ color: 'white', width: '17px', height: '17px' }}
          />
        </IconButton>
      </Box>
      <Box px={'20px'}>
        <Card
          sx={{
            mb: 0,
            p: 0,
            boxShadow: 'none',
            borderRadius: 0,
          }}
        >
          <CardContent sx={{ padding: 0, pt: post.images?.length === 0 ? 4 : 0 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pt={2}
            >
              <Typography variant="h6" sx={{ mt: 0.5, fontWeight: '600' }}>
                {post.title}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  {formatDate(post.date_posted)}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {post.description}
            </Typography>
          </CardContent>
        </Card>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Button
            variant="contained"
            startIcon={<Icons.chatIcon size={20} color="white" />}
            color="secondary"
            sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}
            disabled={post.author === userId}
            onClick={() => {
              navigate(`/messages?userId=${post.author}`);
            }}
          >
            Message
          </Button>
          <Button
            variant="outlined"
            startIcon={<Share />}
            sx={{
              boxShadow: 'none',
              color: '#000',
              fontSize: '11px',
              padding: '6px 12px',
              ml: 2,
              border: '1px solid black',
              '&:hover': {
                borderColor: 'black',
                opacity: 0.75,
              },
            }}
            onClick={handleShareClick}
          >
            Share
          </Button>
        </Box>
        <Typography
          variant="h6"
          sx={{ mt: 4, mb: 2, pb: 1, borderBottom: '1px solid #9A9A9A' }}
        >
          Similar posts
        </Typography>
        <Grid container spacing={0.5}>
          {similarPosts.map((similarPost, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box
                onClick={() => navigate(`/post/${similarPost.id}`)}
                sx={{ cursor: 'pointer' }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    padding: 2,
                    height: '100%',
                    boxShadow: 'none',
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {similarPost.authorPic ? (
                      <Avatar src={similarPost.authorPic} sx={{ mr: 2 }} />
                    ) : (
                      <Avatar sx={{ mr: 2 }}>{similarPost.author[0]}</Avatar>
                    )}
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {authorNames[similarPost.author] || 'Unknown'}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          columnGap: '0.5rem',
                        }}
                      >
                        {similarPost.is_urgent && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{
                              backgroundColor: '#FFA500',
                              padding: '0.1rem 0.3rem',
                              fontSize: '0.7rem',
                              borderRadius: '2px',
                              color: 'white',
                            }}
                          >
                            Urgent
                          </Typography>
                        )}
                        <Typography variant="body2" color="textSecondary">
                          {formatDate(similarPost.date_posted)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography variant="h6" sx={{ mt: 0.5 }}>
                    {similarPost.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 0.5, mb: 1 }}>
                    {similarPost.description}
                  </Typography>
                  {similarPost.images && similarPost.images.length > 0 && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={enforceHttps(similarPost.images)} // Assuming first image for similar posts
                      alt={similarPost.title}
                      sx={{ mt: 1, borderRadius: '4px' }}
                    />
                  )}
                </Paper>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          URL copied to clipboard!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PostDetailsPage;
