import React, { useState, useEffect } from 'react'
import PostItem from '../CompItems/PostItem'
import {
  fetchNearbyPosts,
  fetchUserPosts,
  fetchUserId,
  fetchProfile,
  fetchUserById,
  deletePost,
} from '../api/apiService'
import { Skeleton } from '@mui/material'
import { format } from 'date-fns'

interface PostListProps {
  showButton?: boolean
  isFeedPage?: boolean
  postType?: string
  loading?: boolean
}

const PostList: React.FC<PostListProps> = ({
  showButton = false,
  isFeedPage = false,
  postType = 'all',
  loading = false,
}) => {
  const [filteredPosts, setFilteredPosts] = useState<any[]>([])
  const [userId, setUserId] = useState<number | null>(null)
  const [userFriends, setUserFriends] = useState<number[]>([])
  const [authorNames, setAuthorNames] = useState<{ [key: number]: string }>({})
  const [authorPics, setAuthorPics] = useState<{ [key: number]: string }>({})

  useEffect(() => {
    const getUserId = async () => {
      try {
        const id = await fetchUserId()
        setUserId(id)
        const profile = await fetchProfile(id)
        setUserFriends(profile.friends)
      } catch (error) {
        console.error('Error fetching user ID:', error)
      }
    }

    getUserId()
  }, [])

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (isFeedPage) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                const latitude = position.coords.latitude
                const longitude = position.coords.longitude
                const nearbyPosts = await fetchNearbyPosts(latitude, longitude)
                filterAndSetPosts(nearbyPosts)
                fetchAuthorDetails(nearbyPosts)
              },
              (error) => console.error('Error getting location:', error)
            )
          } else {
            console.error('Geolocation is not supported by this browser.')
          }
        } else if (userId !== null) {
          const userPosts = await fetchUserPosts(userId)
          filterAndSetPosts(userPosts)
          fetchAuthorDetails(userPosts)
        }
      } catch (error) {
        console.error('Error fetching posts:', error)
      }
    }

    fetchPosts()
  }, [isFeedPage, userId, postType])

  const filterAndSetPosts = (posts: any[]) => {
    let filtered = posts
    if (postType === 'request') {
      filtered = posts.filter((post) => post.post_type === 'request')
    } else if (postType === 'offer') {
      filtered = posts.filter((post) => post.post_type === 'offer')
    } else if (postType === 'friends') {
      filtered = posts.filter((post) => userFriends.includes(post.author))
    }
    setFilteredPosts(filtered)
  }

  const fetchAuthorDetails = async (posts: any[]) => {
    const authorNamesMap: { [key: number]: string } = {}
    const authorPicsMap: { [key: number]: string } = {}

    for (const post of posts) {
      try {
        const author = await fetchUserById(post.author)
        const name =
          author.first_name && author.last_name
            ? `${author.first_name} ${author.last_name}`
            : author.username
        authorNamesMap[post.author] = name
        const profile = await fetchProfile(author.id)
        authorPicsMap[post.author] = profile.profile_pic
      } catch (error) {
        console.error(
          `Error fetching author details for post ${post.id}:`,
          error
        )
      }
    }

    setAuthorNames(authorNamesMap)
    setAuthorPics(authorPicsMap)
  }

  const handleDelete = async (postId: number) => {
    try {
      await deletePost(postId)
      setFilteredPosts((prevPosts) =>
        prevPosts.filter((post) => post.id !== postId)
      )
    } catch (error) {
      console.error(`Error deleting post ${postId}:`, error)
    }
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return format(date, 'PPpp')
  }

  return (
    <div>
      {loading
        ? Array.from(new Array(5)).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={200}
              sx={{ mb: 2 }}
            />
          ))
        : filteredPosts.map((post: any, index: number) => (
            <PostItem
              key={index}
              id={post.id}
              author={authorNames[post.author] || 'Unknown'}
              authorPic={authorPics[post.author] || undefined}
              time={formatDate(post.date_posted)}
              title={post.title}
              content={post.description}
              image={post.thumbnail ? post.thumbnail : undefined}
              urgent={post.is_urgent}
              postType={post.post_type} // Pass post type to PostItem
              showButton={showButton}
              onDelete={() => handleDelete(post.id)}
            />
          ))}
    </div>
  )
}

export default PostList
