import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, MenuItem, Select, FormControl, SelectChangeEvent, useMediaQuery, Skeleton } from '@mui/material';
import CategoryList from '../CompLists/CategoryList';
import ShopItemList from '../CompLists/ShopItemList';
import SearchBar from '../components/searchBar';
import { colors } from '../config/theme';
import useShops from '../hooks/useShops';
import ShopHelper from '../helpers/ShopHelper';
import { useTheme } from '@mui/material/styles';

interface Shop {
  id: number;
  logo: string;
  background_image: string;
  business_name: string;
  website: string;
  location: string;
  description: string;
  category: string;
}

const ShopPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [filteredShops, setFilteredShops] = useState<Shop[]>([]);
  const { shops, loading, error } = useShops();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortOption(event.target.value);
    // Add sort logic here if needed
  };

  useEffect(() => {
    const filtered = shops.filter(shop =>
      shop.business_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredShops(filtered);
  }, [searchTerm, shops]);

  useEffect(() => {
    setFilteredShops(shops);
  }, [shops]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Container sx={{ mt: 3, padding: '20px', flexGrow: 1, width: '100%', maxWidth: '100%' }} disableGutters>
          {loading ? (
            <>
              <Skeleton variant="text" width={210} height={40} />
              <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 2 }} />
              <Skeleton variant="text" width={150} height={30} sx={{ mt: 2 }} />
              <Skeleton variant="rectangular" height={120} width="100%" sx={{ mt: 2 }} />
              <Skeleton variant="text" width={150} height={30} sx={{ mt: 2 }} />
              <Skeleton variant="rectangular" height={40} width="100%" sx={{ mt: 2 }} />
            </>
          ) : (
            <>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }} pb={2}>
                Shop
              </Typography>
              <SearchBar
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                onSearchSubmit={(e) => e.preventDefault()} // Prevent form submission as we are filtering as you type
                backgroundColor={colors.brandGrayBG}
              />
              <Typography variant="h6" mt={2}>
                Top Categories
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <CategoryList shops={shops} setFilteredShops={setFilteredShops} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1.5, mb: 2, pb: 1, borderBottom: '1px solid black' }}>
                <Box>
                  <Typography variant="h6">
                    All Shops
                  </Typography>
                  <Typography variant="body2" sx={{ color: colors.brandGray50, fontSize: '12px', mt: 0, pt: 0 }}>
                    {filteredShops.length} Results
                  </Typography>
                </Box>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 100 }}>
                  <Select
                    value={sortOption}
                    onChange={handleSortChange}
                    displayEmpty
                    sx={{
                      fontSize: '14px',
                      '& .MuiSelect-select': {
                        padding: '8px 10px',
                      }
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Typography variant="body2" sx={{ fontSize: '14px' }}>Sort By</Typography>
                    </MenuItem>
                    <MenuItem value={'popularity'}>
                      <Typography variant="body2" sx={{ fontSize: '14px' }}>Popularity</Typography>
                    </MenuItem>
                    <MenuItem value={'rating'}>
                      <Typography variant="body2" sx={{ fontSize: '14px' }}>Rating</Typography>
                    </MenuItem>
                    <MenuItem value={'newest'}>
                      <Typography variant="body2" sx={{ fontSize: '14px' }}>Newest</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
          <ShopItemList shops={filteredShops} loading={loading} />
        </Container>
      </Box>
      {!isMobile && (
        <Box sx={{ flexShrink: 0, height: '100vh', width: '20vw', backgroundColor: '#DAEBEE', position: 'sticky', top: 0 }}>
          {loading ? (
            <>
              <Skeleton variant="rounded" height={20} width="30%" sx={{ m: 2 ,mt:3}} />
              <Skeleton variant="rounded" height={30} width="50%"  sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={250}  sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={250}  sx={{ m: 2 }} />
            </>
          ) : (
            <ShopHelper shops={shops} setFilteredShops={setFilteredShops} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ShopPage;
