import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { fetchNearbyPosts } from '../../api/apiService';
import { useNavigate } from 'react-router-dom';
import { enforceHttps } from '../../utils/utility'; // Added import

interface FeaturedProps {
  loading: boolean;
  onLoadingComplete: () => void;
}

const Featured: React.FC<FeaturedProps> = ({ loading, onLoadingComplete }) => {
  const [urgentPosts, setUrgentPosts] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUrgentPosts = async () => {
      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              const nearbyPosts = await fetchNearbyPosts(latitude, longitude);
              const urgentPosts = nearbyPosts.filter((post: any) => post.is_urgent).slice(0, 4);
              setUrgentPosts(urgentPosts);
              onLoadingComplete();
            },
            (error) => console.error('Error getting location:', error)
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchUrgentPosts();
  }, []);

  const handleCardClick = (postId: number) => {
    navigate(`/post/${postId}`);
  };

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap="8px" padding="7px 0">
      {loading ? (
        Array.from(new Array(4)).map((_, index) => (
          <Skeleton key={index} variant="rectangular" width="100%" height="8vh" sx={{ mb: 2 }} />
        ))
      ) : (
        urgentPosts.map((post, index) => (
          <Box key={index} display="flex" alignItems="center" onClick={() => handleCardClick(post.id)}>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
            >
              <img
                src={post.thumbnail ? enforceHttps(post.thumbnail) : undefined} // Applied enforceHttps
                style={{ width: '100%', height: '8vh' }}
              />
              <Typography
                variant="body2"
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  textAlign: 'start',
                  padding: '5px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: post.thumbnail ? 'white' : 'black', // Conditionally set color
                }}
              >
                {post.title.length > 20 ? post.title.slice(0, 17) + '...' : post.title}
              </Typography>
            </div>
          </Box>
        ))
      )}
    </Box>
  );
};

export default Featured;
