import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Avatar,
  InputAdornment,
  Snackbar,
  SnackbarContent,
} from '@mui/material'
import CustomTextField from './CustomTextField'
import CustomButton from '../CustomButton'
import { fetchProfile, updateProfile, fetchUserId } from '../../api/apiService' // Adjust import path as needed
import { Profile } from '../../types/models' // Adjust import path as needed

const UserDetailsForm: React.FC = () => {
  const [userId, setUserId] = useState<number | null>(null)
  const [profile, setProfile] = useState<Profile | null>(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [bio, setBio] = useState('')
  const [profilePic, setProfilePic] = useState<File | null>(null)
  const [profilePicUrl, setProfilePicUrl] = useState<string>('')
  const [countryCode, setCountryCode] = useState('')

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  useEffect(() => {
    const initializeUserProfile = async () => {
      try {
        const id = await fetchUserId()
        setUserId(id)
        const profileData = await fetchProfile(id)
        setProfile(profileData)
        setFirstName(profileData.first_name)
        setLastName(profileData.last_name)
        setEmail(profileData.email)
        setPhoneNumber(profileData.phone_number)
        setBio(profileData.bio)
        setCountryCode(profileData.country_code) // Assuming country_code is part of profileData
        if (profileData.profile_pic) {
          setProfilePicUrl(profileData.profile_pic)
        }
      } catch (error) {
        console.error('Error initializing user profile:', error)
      }
    }

    initializeUserProfile()
  }, [])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (userId === null) {
      console.error('User ID is null')
      setSnackbarMessage('User ID is not available.')
      setSnackbarOpen(true)
      return
    }

    const formData = new FormData()
    if (firstName) formData.append('first_name', firstName)
    if (lastName) formData.append('last_name', lastName)
    if (email) formData.append('email', email)
    if (phoneNumber) formData.append('phone_number', phoneNumber)
    if (bio) formData.append('bio', bio)
    if (countryCode) formData.append('country_code', countryCode) // Include countryCode in FormData

    if (profilePic) formData.append('profile_pic', profilePic)

    try {
      const updatedProfile = await updateProfile(userId, formData)
      setProfile(updatedProfile)
      setSnackbarMessage('Profile updated successfully!')
      setSnackbarOpen(true)
    } catch (error) {
      console.error('Error updating profile:', error)
      setSnackbarMessage('Failed to update profile.')
      setSnackbarOpen(true)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setProfilePic(event.target.files[0])
      setProfilePicUrl(URL.createObjectURL(event.target.files[0]))
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Typography variant="h6">User details</Typography>
      <Typography variant="body2" color="textSecondary">
        Update your personal details and photo here
      </Typography>
      <Box py={1} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          sx={{ width: 80, height: 80, borderRadius: 1 }}
          src={profilePicUrl}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: 1,
          }}
        >
          <CustomButton variant="contained" component="label">
            Upload
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileChange}
            />
          </CustomButton>
          <Typography variant="caption" color="textSecondary">
            Select your photo up to 5mb
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomTextField
          label="First name"
          placeholder="Name"
          fullWidth
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          sx={{ flex: 1 }}
        />
        <CustomTextField
          label="Last Name"
          placeholder="Last Name"
          fullWidth
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          sx={{ flex: 1 }}
        />
      </Box>
      <CustomTextField
        label="Email"
        placeholder="Email"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomTextField
          label="Country code"
          placeholder="123"
          sx={{ width: { md: '25%', xs: '40%', sm: '25%' } }}
          InputProps={{
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        />
        <CustomTextField
          label="Phone number"
          placeholder="Number"
          fullWidth
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Box>
      <CustomTextField
        label="Bio"
        placeholder="Write your Bio here e.g your hobbies, interests ETC"
        multiline
        rows={4}
        fullWidth
        value={bio}
        onChange={(e) => setBio(e.target.value)}
      />
      <Box sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
        <CustomButton variant="contained" color="primary" type="submit">
          Update profile
        </CustomButton>
        <CustomButton
          variant="outlined"
          color="primary"
          onClick={() => window.location.reload()}
        >
          Reset
        </CustomButton>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent message={snackbarMessage} />
      </Snackbar>
    </Box>
  )
}

export default UserDetailsForm
