import { useEffect, useState } from 'react'
import { fetchProfiles } from 'src/api/apiService'
import { Profile } from 'src/types/models'

const useUsers = () => {
  const [users, setUsers] = useState<Profile[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const loadProfiles = async () => {
      setLoading(true)
      try {
        const users = await fetchProfiles()
        setUsers(users)
      } catch (err) {
        console.error('Failed to fetch profiles', err)
      } finally {
        setLoading(false)
      }
    }

    loadProfiles()
  }, [])

  return { users, loading }
}

export default useUsers
