import React from 'react'

const ProfileHelper: React.FC = () => {
  return (
    <div>
      <h3>Profile Details</h3>
      <p>Additional information related to the profile.</p>
    </div>
  )
}

export default ProfileHelper
