import React, { useState, useEffect } from 'react';
import { Typography, Box, Container, Skeleton } from '@mui/material';
import PostList from '../CompLists/PostList';
import Profile from '../components/profil/Profile';

const ProfilePage: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const handleProfileLoadingComplete = () => {
    setLoading(false);
  };

  return (
    <Container sx={{ mt: 3, padding: '20px', mb: 2 }} disableGutters>
      {loading ? (
        <Skeleton variant="text" width={210} height={40} />
      ) : (
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Profile
        </Typography>
      )}
      <Box mt={1}>
        <Profile loading={loading} onLoadingComplete={handleProfileLoadingComplete} />
      </Box>
      <Typography variant="h6" mt={3} mb={-2} sx={{ fontWeight: 600 }}>
        Your posts
      </Typography>
      <Box mt={2}>
        <PostList showButton={true} isFeedPage={false} loading={loading} />
      </Box>
    </Container>
  );
};

export default ProfilePage;
