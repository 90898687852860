import React from 'react';
import { Box, TextField, Typography, TextFieldProps, InputAdornment, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../config/theme';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '12px 12px', // Adjust the padding values as needed
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87)', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.brandGray50, // Focused border color
    },
  },
});

// Define prop types for CustomTextField
interface CustomTextFieldProps extends Omit<TextFieldProps, 'label'> {
  label: string;
  sx?: SxProps<Theme>;
}

// CustomTextField component
const CustomTextField: React.FC<CustomTextFieldProps> = ({ label, sx, ...props }) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', ...sx }}>
      <Typography variant="body2" color={focused ? colors.brandGray50 : 'textSecondary'}>
        {label}
      </Typography>
      <StyledTextField
        {...props}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </Box>
  );
};

export default CustomTextField;
