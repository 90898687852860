import axios from 'axios'

export const BASE_URL = 'https://live-free-life-nation-back.up.railway.app'

const instance = axios.create({
  baseURL: 'https://live-free-life-nation-back.up.railway.app/', // Django backend URL
  timeout: 9000,
  headers: { 'Content-Type': 'application/json' },
})

// Function to set the Content-Type header for multipart/form-data
export const setMultipartFormDataHeader = () => {
  instance.defaults.headers['Content-Type'] = 'multipart/form-data'
}

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken')
    if (token) {
      config.headers.Authorization = `Token ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor to handle invalid tokens
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const token = localStorage.getItem("authToken");
      if (token) {
        // Retry after a short delay to allow backend processing
        await new Promise((resolve) => setTimeout(resolve, 700));
        return axios(error.config);
      }
      localStorage.removeItem("authToken"); // Remove invalid token if retry fails
    }
    return Promise.reject(error);
  }
);
instance.interceptors.request.use((config) => {
  if (config.url && config.url.startsWith('http//')) {
      config.url = config.url.replace('http//', 'http://');
  }
  return config;
});

export default instance
