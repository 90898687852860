import React from 'react';
import { Card, CardContent, Typography, Button, Box, CardMedia } from '@mui/material';
import { colors } from '../config/theme';

interface EventItemProps {
  date: string;
  title: string;
  location: string;
  price: string;
  image: string;
  link: string;
}

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const formatPrice = (price: string) => {
  return `$${price}`;
};

const EventItem: React.FC<EventItemProps> = ({ date, title, location, price, image, link }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 'none', backgroundColor: colors.brandGrayBGLight }}>
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={title}
      />
      <CardContent sx={{ flexGrow: 1, p: '5px 10px' }}>
        <Typography variant="h6" sx={{ fontSize: '18px' }}>{title}</Typography>
        <Typography variant="body2" color={colors.brandGray} mt={1}>{formatDate(date)} | {location}</Typography>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
        <Typography variant="body2">{formatPrice(price)}</Typography>
        <Button variant="contained" sx={{ boxShadow: 'none', backgroundColor: colors.brandSecondary, color: 'white', fontSize: '10px', padding: '8px 12px' }} href={link} target="_blank" rel="noopener noreferrer">
          Book Ticket
        </Button>
      </Box>
    </Card>
  );
};

export default EventItem;
