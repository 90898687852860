import React from 'react';
import { Avatar, Button, Box, Typography } from '@mui/material';

interface SuggestionProfileProps {
  image?: string;
  firstName?: string;
  lastName?: string;
  username: string;
  isFollowing: boolean;
  onFollowUnfollow: () => void;
}

const SuggestionProfile: React.FC<SuggestionProfileProps> = ({
  image,
  firstName,
  lastName,
  username,
  isFollowing,
  onFollowUnfollow,
}) => {
  const displayName = (firstName && lastName) ? `${firstName} ${lastName}` : username;

  // Determine max length based on screen width
  const maxLength = window.innerWidth < 1350 ? 11 : 17;
  const displayNameShortened = displayName.length > maxLength ? `${displayName.substring(0, maxLength)}...` : displayName;
  
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="10px">
      <Box display="flex" alignItems="center">
        {image ? (
          <Avatar src={image} sx={{ mr: 1, width: 42, height: 42 }} />
        ) : (
          <Avatar sx={{ mr: 1, width: 42, height: 42 }}>{displayName ? displayName[0] : ''}</Avatar>
        )}
        <Typography variant="body1" sx={{ fontSize: '0.85rem' }}>
          {displayNameShortened}
        </Typography>
      </Box>
      <Button
        variant={isFollowing ? 'outlined' : 'contained'}
        size="small"
        style={{
          fontSize: '0.6rem',
          padding: '5px 2px',
          boxShadow: 'none',
          borderRadius: '2px',
          backgroundColor: isFollowing ? 'transparent' : 'black',
          color: isFollowing ? 'black' : 'white',
        }}
        onClick={onFollowUnfollow}
      >
        {isFollowing ? 'Unfollow' : 'Follow'}
      </Button>
    </Box>
  );
};

export default SuggestionProfile;
