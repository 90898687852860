import React from 'react';
import { Button, Typography } from '@mui/material';
import { Icons } from '../assets/index';
import { colors } from '../config/theme';
import { useNavigate } from 'react-router-dom';

const CreatePostButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      fullWidth
      sx={{
        backgroundColor: '#EBEBEB',
        justifyContent: 'space-between',
        textTransform: 'none',
        color: 'grey',
        padding: '12px 16px',
        borderRadius: '4px',
        boxShadow: 'none',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#DDDDDD',
          boxShadow: 'none',
          cursor: 'pointer',
        },
      }}
      onClick={() => navigate('/create-post')}
    >
      <Typography variant="body1" color={colors.brandGray}>
        Create New Post
      </Typography>
      <Icons.AddPost />
    </Button>
  );
};

export default CreatePostButton;
