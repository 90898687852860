import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import EventItem from '../CompItems/EventItem';
import CustomButton from '../components/CustomButton';
import noevent from '../assets/media/noevent.svg';

interface Event {
  id: number;
  image: string;
  title: string;
  date: string;
  location: string;
  price: string;
  link: string;
}

interface EventItemsListProps {
  containerRef: React.RefObject<HTMLDivElement>;
  events: Event[];
  loading?: boolean; // Add the loading prop here
}

const EventItemsList: React.FC<EventItemsListProps> = ({ containerRef, events, loading = false }) => {
  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height={200} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={200} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={200} />
      </>
    );
  }

  if (!events.length) {
    return (
      <Box sx={{ textAlign: 'center', padding: '20px', color: 'gray' }}>
        <img
          src={noevent}
          alt="No events"
          style={{ width: '44px' }}
        />
        <Typography variant="h6">No Events Available</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom: '16px' }}>
          Stay tuned! We’ll have more events coming soon.
        </Typography>
        {/* <CustomButton variant="contained" onClick={() => console.log('Add Event')}>
          Add Your Event
        </CustomButton> */}
      </Box>
    );
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        overflowX: 'auto',
        '& > *': {
          flex: '0 0 auto',
          marginRight: '16px',
        },
        '& > *:last-child': {
          marginRight: 0,
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {events.map((event, index) => (
        <Box key={index} sx={{ minWidth: { md: '31.9%', sm: '50%', xs: '90%' }, maxWidth: { md: '31.9%', sm: '50%', xs: '90%' }, flexShrink: 0 }}>
          <EventItem {...event} />
        </Box>
      ))}
    </Box>
  );
};

export default EventItemsList;
