import { useEffect, useState } from 'react'
import { fetchChats, fetchMessages, fetchUserId } from 'src/api/apiService'
import { Chat } from 'src/types/models'

const useChats = (trigger: boolean) => {
  const [chat, setChat] = useState<Chat[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initializeChat = async () => {
      setLoading(true)
      try {
        const userId = await fetchUserId()
        const chatData: Chat[] = await fetchChats()
        console.log('chatData:', chatData)
        // Filter out chats where the user is not a participant
        const userChats = chatData.filter((c) =>
          c.participants.includes(userId)
        )

        // Fetch messages for each chat
        await Promise.all(
          userChats.map(async (c: Chat) => {
            const messages = await fetchMessages(c.id)
            c.messages = messages
          })
        )
        setChat(userChats)
      } catch (error) {
        console.error('Error initializing chat:', error)
      }
      setLoading(false)
    }

    initializeChat()
  }, [trigger]) // Add trigger as a dependency

  return { chat, loading }
}

export default useChats
