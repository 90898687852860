import React, { useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import Story from './Story';
import { fetchNearbyUsers, fetchProfile, fetchStories } from '../../api/apiService';

interface StoriesProps {
  loading: boolean;
  onLoadingComplete: () => void;
}

const Stories: React.FC<StoriesProps> = ({ loading, onLoadingComplete }) => {
  const [storiesData, setStoriesData] = useState<
    {
      avatar?: string;
      name: string;
      media: string;
      mediaType: 'image' | 'video';
      hasStory: boolean;
    }[]
  >([]);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.error('Error fetching location:', error);
      }
    );
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      (async () => {
        try {
          const nearbyUsers = await fetchNearbyUsers(latitude, longitude);
          const stories = await fetchStories();
          const nearbyUserProfiles = await Promise.all(
            nearbyUsers.slice(0, 50).map(async (user: any) => {
              const profile = await fetchProfile(user.id);
              const userStory = stories.find((story: any) => story.user === user.id);
              return {
                avatar: profile.profile_pic || '',
                name: `${profile.first_name} ${profile.last_name}`.trim() || 'Unknown',
                media: userStory ? userStory.image : profile.profile_pic || '',
                mediaType: userStory ? 'image' : 'image',
                hasStory: !!userStory,
              };
            })
          );
          setStoriesData(nearbyUserProfiles);
          onLoadingComplete();
        } catch (error) {
          console.error('Error fetching stories:', error);
        }
      })();
    }
  }, [latitude, longitude]);

  return (
    <Box
      display="flex"
      overflow="auto"
      padding="7px 0"
      sx={{
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      {loading ? (
        Array.from(new Array(2)).map((_, index) => (
          <Skeleton key={index} variant="rounded" width={110} height={160} sx={{ mx: 0.5 }} />
        ))
      ) : (
        storiesData.map((story, index) => (
          <Story
            key={index}
            avatar={story.avatar}
            name={story.name}
            media={story.media}
            mediaType={story.mediaType}
            hasStory={story.hasStory}
          />
        ))
      )}
    </Box>
  );
};

export default Stories;
