import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import locationImage from '../assets/media/location.svg';

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Chrome") && !userAgent.includes("Edge")) {
    return "Chrome";
  } else if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Edge")) {
    return "Edge";
  } else {
    return "Other";
  }
};

const withLocationPermission = (WrappedComponent: React.ComponentType, isHelper: boolean = false): React.FC => {
  const WithLocationPermission: React.FC = () => {
    const [locationPermission, setLocationPermission] = useState<boolean | null>(null);
    const [browser, setBrowser] = useState<string>("");

    const requestLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          () => {
            setLocationPermission(true);
          },
          () => {
            setLocationPermission(false);
          }
        );
      } else {
        setLocationPermission(false);
      }
    };

    useEffect(() => {
      setBrowser(detectBrowser());
      requestLocation();
    }, []);

    const renderInstructions = () => {
      switch (browser) {
        case "Chrome":
          return (
            <Typography variant="body2" gutterBottom>
              <strong>Google Chrome:</strong>
              <br />
              1. Click on the three dots menu in the top right corner.
              <br />
              2. Select "Settings".
              <br />
              3. Scroll down and click on "Privacy and security".
              <br />
              4. Click on "Site settings".
              <br />
              5. Click on "Location".
              <br />
              6. Find your site and allow location access.
            </Typography>
          );
        case "Firefox":
          return (
            <Typography variant="body2" gutterBottom>
              <strong>Mozilla Firefox:</strong>
              <br />
              1. Click on the three bars menu in the top right corner.
              <br />
              2. Select "Options".
              <br />
              3. Click on "Privacy & Security".
              <br />
              4. Scroll down to "Permissions" and click on "Settings..." next to "Location".
              <br />
              5. Find your site and allow location access.
            </Typography>
          );
        case "Safari":
          return (
            <Typography variant="body2" gutterBottom>
              <strong>Safari (macOS):</strong>
              <br />
              1. Click on "Safari" in the top menu.
              <br />
              2. Select "Preferences".
              <br />
              3. Go to the "Websites" tab.
              <br />
              4. Select "Location" from the left sidebar.
              <br />
              5. Find your site and set it to "Allow".
            </Typography>
          );
        case "Edge":
          return (
            <Typography variant="body2" gutterBottom>
              <strong>Microsoft Edge:</strong>
              <br />
              1. Click on the three dots menu in the top right corner.
              <br />
              2. Select "Settings".
              <br />
              3. Click on "Site permissions".
              <br />
              4. Click on "Location".
              <br />
              5. Find your site and allow location access.
            </Typography>
          );
        default:
          return (
            <Typography variant="body2" gutterBottom>
              Please enable location services in your browser settings.
            </Typography>
          );
      }
    };

    if (locationPermission === false && !isHelper) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" textAlign="center">
          <img src={locationImage} alt="Enable location" width={150} height={150} />
          <Typography variant='h4' gutterBottom>
            Enable location to use this website
          </Typography>
          {renderInstructions()}
        </Box>
      );
    }

    if (locationPermission === false && isHelper) {
      return null;
    }

    return <WrappedComponent />;
  };

  return WithLocationPermission;
};

export default withLocationPermission;
