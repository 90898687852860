import { useEffect, useState } from 'react'
import { fetchNearbyUsers, fetchUserId } from 'src/api/apiService'
import { Profile } from 'src/types/models'
import { useMyLocation } from './useMyLocation'

const useNearByUsers = () => {
  const [nearByUsers, setNearByUsers] = useState<Profile[]>([])
  const [loading, setLoading] = useState(true)
  const { loading: load, latitude, longitude } = useMyLocation()
  useEffect(() => {
    const initializeNearByUsers = async (
      latitude: number,
      longitude: number
    ) => {
      setLoading(true)
      try {
        const myId = await fetchUserId()
        if (!myId) {
          return
        }

        const users = await fetchNearbyUsers(latitude, longitude)
        setNearByUsers(users.filter((user: Profile) => user.id !== myId))
      } catch (error) {
        console.error('Error initializing nearby users:', error)
      }
      setLoading(false)
    }

    if (!latitude || !longitude) {
      return
    }
    initializeNearByUsers(latitude, longitude)
  }, [latitude, longitude])

  return { nearByUsers, loading: loading || load }
}

export default useNearByUsers
