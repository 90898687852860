import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  CardMedia,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Snackbar,
  IconButton,
  Skeleton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../components/CustomButton';
import { colors } from '../config/theme';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';

interface PostItemProps {
  id: number;
  author: string;
  authorPic?: string;
  time: string;
  title: string;
  content: string;
  image?: string;
  urgent?: boolean;
  showButton?: boolean;
  onDelete?: () => void;
  loading?: boolean;
  postType?: string; // New prop for post type
}

const PostItem: React.FC<PostItemProps> = ({
  id,
  author,
  authorPic,
  time,
  title,
  content,
  image,
  urgent,
  showButton = false,
  onDelete,
  loading = false,
  postType = 'offer', // Default type if not specified
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleDelete = () => {
    setOpenDialog(false); // Close the dialog
    if (onDelete) {
      onDelete();
      setSnackbarOpen(true); // Show snackbar on successful delete
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!openDialog) {
      navigate(`/post/${id}`);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'PPpp'); // Adjust the format as needed
  };

  return (
    <Card
      sx={{
        mb: 2,
        boxShadow: 'none',
        borderBottom: '1px solid #9A9A9A',
        borderRadius: 0,
        '&:hover': { cursor: 'pointer' },
      }}
      onClick={handleCardClick}
    >
      <CardContent sx={{ padding: 2, [theme.breakpoints.down('sm')]: { padding: '20px 0px' } }}>
        {loading ? (
          <>
            <Skeleton variant="circular" width={40} height={40} sx={{ mb: 2 }} />
            <Skeleton variant="text" width="80%" sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" height={150} />
          </>
        ) : (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                {authorPic ? (
                  <Avatar src={authorPic} sx={{ mr: 2 }} />
                ) : (
                  <Avatar sx={{ mr: 2 }}>{author[0]}</Avatar>
                )}
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    {author}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '0.5rem' }}>
                    {urgent && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{
                          backgroundColor: colors.brandOrange,
                          padding: '0.1rem 0.3rem',
                          fontSize: '0.7rem',
                          borderRadius: '2px',
                          color: 'white',
                        }}
                      >
                        Urgent
                      </Typography>
                    )}
                    {/* Post type badge */}
                    {postType === 'offer' && (
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: '#C1FFC1', // Light pink color for offer
                          padding: '0.1rem 0.3rem',
                          fontSize: '0.7rem',
                          borderRadius: '2px',
                          color: 'black',
                        }}
                      >
                        Offer
                      </Typography>
                    )}
                    {postType === 'request' && (
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: '#FFC1E3', // Light green color for request
                          padding: '0.1rem 0.3rem',
                          fontSize: '0.7rem',
                          borderRadius: '2px',
                          color: 'black',
                        }}
                      >
                        Request
                      </Typography>
                    )}
                    <Typography variant="body2" color="textSecondary">
                      {time}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {showButton && (
                <CustomButton
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenDialog(true); // Open delete confirmation dialog
                  }}
                  sx={{ zIndex: 3 }}
                >
                  Delete
                </CustomButton>
              )}
            </Box>
            <Typography variant="h6" sx={{ mt: 1 }}>
              {title}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {content}
            </Typography>
            {image && (
              <CardMedia component="img" height="260" image={image} alt={title} sx={{ mt: 2 }} />
            )}
          </>
        )}
      </CardContent>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this post?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} sx={{ color: colors.brandGray50 }}>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Delete Success */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Post deleted successfully"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Card>
  );
};

export default PostItem;
