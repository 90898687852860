// src/components/message/Message.tsx
import React, { useCallback, useState } from 'react'
import { Box, ListItem, Avatar, Typography } from '@mui/material'
import { colors } from '../../config/theme'
import { Message, Profile } from 'src/types/models'
import { Link } from 'react-router-dom'
import ImageViewer from 'react-simple-image-viewer'

interface MessageProps {
  message: Message
  isOwnMessage: boolean
  showAvatar: boolean
  users: Profile[]
}

const MessageComponent: React.FC<MessageProps> = ({
  message,
  isOwnMessage,
  showAvatar,
  users,
}) => {
  const getAvatar = (message: Message) => {
    const user = users.find((u) => u.id === message.sender)
    if (user?.profile_pic) {
      return user.profile_pic
    }
  }

  const getAvatarName = (message: Message) => {
    const user = users.find((u) => u.id === message.sender)
    return user ? `${user.first_name[0]}${user.last_name[0]}` : ''
  }

  const getMessageSender = (message: Message) => {
    const user = users.find((u: Profile) => u.id === message.sender)
    if (user) {
      return `${user.first_name} ${user.last_name}`
    }
  }
  const isImage = (url: string) => {
    const imageExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tiff',
      'svg',
      'webp',
      'heic',
      'heif',
      'ico',
      'jfif',
    ]
    const extension = url.split('.').pop()?.toLowerCase() || ''
    return imageExtensions.includes(extension)
  }
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        p: 0.3,
        justifyContent: isOwnMessage ? 'flex-end' : 'flex-start',
        textAlign: isOwnMessage ? 'right' : 'left',
      }}
    >
      {!isOwnMessage && showAvatar && (
        <Box display="flex" flexDirection="column" alignItems="center">
          {getAvatar(message) ? (
            <Avatar
              src={getAvatar(message)}
              sx={{
                width: 30,
                height: 30,
                borderRadius: 1,
                marginBottom: 1,
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: 30,
                height: 30,
                borderRadius: 1,
                marginBottom: 1,
              }}
            >
              {getAvatarName(message)}
            </Avatar>
          )}
        </Box>
      )}
      <Box
        ml={showAvatar && !isOwnMessage ? 1 : 4.75}
        mr={showAvatar && !isOwnMessage ? 4.75 : 1}
        bgcolor={isOwnMessage ? colors.brandPrimary : colors.brandGrayBG}
        px={1.5}
        py={0.5}
        borderRadius={2}
        sx={{
          maxWidth: '75%',
          display: 'inline-block',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
          marginBottom: showAvatar && !isOwnMessage ? 0 : 0,
          textAlign: isOwnMessage ? 'start' : 'start',
        }}
      >
        {showAvatar && !isOwnMessage && (
          <Typography
            variant="subtitle2"
            color={colors.brandSecondary}
            sx={{ fontWeight: 600 }}
          >
            {getMessageSender(message)}
          </Typography>
        )}

        {message.attachment ? (
          isImage(String(message.attachment)) ? (
            <Box
              display="flex"
              flexDirection={isOwnMessage ? 'row-reverse' : 'row'}
              alignItems="center"
              my={1}
            >
              <img
                src={String(message.attachment)}
                onClick={() => openImageViewer(0)}
                alt={message.text}
                style={{ maxWidth: '100%', maxHeight: 200 }}
              />
              {isViewerOpen && (
                <ImageViewer
                  src={[String(message.attachment)]}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              )}
            </Box>
          ) : (
            <Link
              to={String(message.attachment)}
              target="_blank"
              rel="noopener noreferrer"
              color={isOwnMessage ? 'white' : 'black'}
            >
              {String(message.attachment).split('/').pop()}
            </Link>
          )
        ) : (
          <Typography variant="body1" color={isOwnMessage ? 'white' : 'black'}>
            {message.text}
          </Typography>
        )}

        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption" color="textSecondary">
            {new Date(message.timestamp).toLocaleTimeString()}
          </Typography>
        </Box>
      </Box>
      {isOwnMessage && (
        <Box display="flex" flexDirection="column" alignItems="center">
          {getAvatar(message) ? (
            <Avatar
              src={getAvatar(message)}
              sx={{
                width: 30,
                height: 30,
                borderRadius: 1,
                marginBottom: 1,
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: 30,
                height: 30,
                borderRadius: 1,
                marginBottom: 1,
              }}
            >
              {getAvatarName(message)}
            </Avatar>
          )}
        </Box>
      )}
    </ListItem>
  )
}

export default MessageComponent
