import { useEffect, useRef, useState } from 'react';
import { Box, Grid, useMediaQuery, IconButton, Drawer, Skeleton, Typography, Avatar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../components/message/Sidebar';
import ChatHeader from '../components/message/ChatHeader';
import MessageList from '../components/message/MessageList';
import MessageInput from '../components/message/MessageInput';
import MessagesHelper from '../helpers/MessagesHelper';
import useMyProfile from 'src/hooks/useMyProfile';
import useNearByUsers from 'src/hooks/useNearByUsers';
import useChats from 'src/hooks/useChats';
import { Chat, Message, Profile } from 'src/types/models';
import { createChat, markMessagesAsRead, sendMessage } from 'src/api/apiService';
import {
  createWebSocketConnection,
  sendMessage as sendWsMessage,
} from '../services/websocketService';
import useUsers from 'src/hooks/useUsers';
import useChatStore from 'src/store/unreadMessagesStore';
import { useLocation } from 'react-router-dom';

interface WebSocketMessageEvent extends MessageEvent {
  data: string;
}

// Extend the Message type to include an optional pendingId property for temporary identification
interface MessageWithPendingId extends Message {
  pendingId?: number;
}

const MessagesPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  console.log('userId', userId);
  const [chats, setChats] = useState<Chat[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [users, setUsers] = useState<Profile[]>([]);
  const [nearbyUsers, setNearbyUsers] = useState<Profile[]>([]);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const socketRef = useRef<WebSocket | null>(null);
  const { activeChat, setActiveChat, setMessageCount } = useChatStore();
  const { userId: myUserId, loading: profileLoading } = useMyProfile();
  const { nearByUsers, loading: usersLoading } = useNearByUsers();
  const { chat: fetchedChat, loading: chatLoading } = useChats(triggerFetch);
  const { users: allUsers, loading: allUsersLoading } = useUsers();

  const isLoaded =
    !profileLoading && !usersLoading && !chatLoading && !allUsersLoading;

  // Determine if device is mobile
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isLoaded) {
      setUsers(allUsers);
      setChats(fetchedChat);
      if (!activeChat && fetchedChat.length > 0) {
        setActiveChat(fetchedChat[0]);
      }
    }
  }, [isLoaded]);

  useEffect(() => {
    const oneOnOneChats = chats.filter((chat) => chat.participants.length === 2);
    const usersInChats = oneOnOneChats.flatMap((chat) => chat.participants);
    setNearbyUsers(
      nearByUsers.filter((user) => !usersInChats.includes(user.id))
    );
  }, [chats, nearByUsers]);

  useEffect(() => {
    if (userId && myUserId) {
      const id = parseInt(userId);
      const existingChat = chats.find(
        (chat) =>
          chat.participants.length === 2 &&
          chat.participants.includes(id) &&
          chat.participants.includes(myUserId)
      );
      console.log('existing chat', existingChat);
      if (existingChat) {
        setActiveChat(existingChat);
      } else {
        const user = users.find((user) => user.id === id);
        console.log('user', user);
        if (user) {
          createChatFn(user);
        }
      }
    }
  }, [users]);

  useEffect(() => {
    const countAllUnreadMessages = chats.reduce((acc, chat) => {
      if (chat.messages.length === 0) {
        return acc;
      }
      const unreadMessages = chat.messages?.filter(
        (message) =>
          message.sender !== (myUserId ?? 0) &&
          !message.read_by.includes(myUserId ?? -1)
      );

      return acc + (unreadMessages.length || 0);
    }, 0);

    setMessageCount(countAllUnreadMessages);
  }, [chats, myUserId, setMessageCount]);

  useEffect(() => {
    if (isLoaded) {
      if (activeChat) {
        setChats((prevChats) => {
          const updatedChats = prevChats.map((chat) => {
            if (chat.id === activeChat.id) {
              const updatedMessages = chat.messages?.map((message) => {
                if (
                  message.sender !== myUserId &&
                  !message.read_by.includes(myUserId!)
                ) {
                  return {
                    ...message,
                    read_by: [...message.read_by, myUserId!],
                  };
                }
                return message;
              });
              return { ...chat, messages: updatedMessages };
            }
            return chat;
          });
          return updatedChats;
        });
        markMessagesAsRead(activeChat.id, myUserId!);
      }
    }
  }, [activeChat, myUserId, isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      if (activeChat) {
        socketRef.current = createWebSocketConnection(activeChat.id.toString());

        socketRef.current.onmessage = (event: WebSocketMessageEvent) => {
          const data = JSON.parse(event.data);
          if (data.message) {
            setChats((prevChats) => {
              const updatedChats = prevChats.map((chat) => {
                if (chat.id === activeChat.id) {
                  const messages = chat.messages || [];
                  const pendingMessageIndex = messages.findIndex(
                    (m: MessageWithPendingId) =>
                      m.pendingId && m.pendingId === data.message.pendingId
                  );

                  if (pendingMessageIndex !== -1) {
                    // Replace the pending message
                    messages[pendingMessageIndex] = { ...data.message, pendingId: undefined };
                  } else {
                    // Add as a new message if no pending message is found
                    messages.push(data.message);
                  }

                  return { ...chat, messages };
                }
                return chat;
              });

              const updatedActiveChat = updatedChats.find(
                (chat) => chat.id === activeChat.id
              );
              setActiveChat(updatedActiveChat!);

              return updatedChats;
            });
          }
          console.log('all chats update', chats);
        };

        return () => {
          socketRef.current?.close();
        };
      }
    }
  }, [activeChat, isLoaded]);
  
  const addMessage = async (message: {
    sender: number;
    text?: string;
    attachment?: File;
    isFile?: boolean;
  }) => {
    const tempId = Date.now(); // Temporary ID for pending message
    let newMessage: MessageWithPendingId;
    if (message.isFile) {
      newMessage = {
        sender: myUserId!,
        attachment: message.attachment,
        read_by: [myUserId!],
        chat: activeChat!.id,
        timestamp: new Date().toISOString(),
        pendingId: tempId, // Adding pendingId to identify locally
      };
    } else {
      newMessage = {
        sender: myUserId!,
        text: message.text,
        read_by: [myUserId!],
        chat: activeChat!.id,
        timestamp: new Date().toISOString(),
        pendingId: tempId, // Adding pendingId to identify locally
      };
    }

    // Show message immediately in the UI with pending status
    setChats((prevChats) => {
      const updatedChats = prevChats.map((chat) => {
        if (chat.id === activeChat!.id) {
          return {
            ...chat,
            messages: chat.messages ? [...chat.messages, newMessage] : [newMessage],
          };
        }
        return chat;
      });
      return updatedChats;
    });

    // Send message to backend
    const resmessage = await sendMessage(activeChat!.id, newMessage);
    sendWsMessage(socketRef.current!, { ...resmessage, pendingId: tempId });
  };

  const createGroupChat = (groupName: string, selectedUsers: number[]) => {
    const formData = new FormData();
    selectedUsers.forEach((user) => {
      formData.append('participants', user.toString());
    });
    formData.append('participants', myUserId!.toLocaleString());
    formData.append('chat_name', groupName);

    createChat(formData).then((newChat) => {
      newChat.messages = [];
      setActiveChat(newChat);
      setTriggerFetch((prev) => !prev); // Trigger a re-fetch
    });
  };

  const createChatFn = (participant: Profile) => {
    const formData = new FormData();
    formData.append('participants', participant.id.toString());
    formData.append('participants', myUserId!.toLocaleString());

    createChat(formData).then((newChat) => {
      setActiveChat(newChat);
      setTriggerFetch((prev) => !prev); // Trigger a re-fetch
    });
  };

  return (
    <Box display="flex" p={0} height="100vh" width="100%">
      {isMobile ? (
        <>
          <IconButton
            onClick={() => setIsSidebarOpen(true)}
            sx={{ position: 'absolute', top: 10, left: 10 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            sx={{ width: '70vw' }}
          >
            <Sidebar
              myId={myUserId!}
              chats={chats}
              activeChat={activeChat!}
              setActiveChat={setActiveChat}
              users={users}
              nearbyUsers={nearbyUsers}
              createGroupChat={createGroupChat}
              createChat={createChatFn}
            />
          </Drawer>
        </>
      ) : (
        <Sidebar
          myId={myUserId!}
          chats={chats}
          activeChat={activeChat!}
          setActiveChat={setActiveChat}
          users={users}
          nearbyUsers={nearbyUsers}
          createGroupChat={createGroupChat}
          createChat={createChatFn}
        />
      )}

      <Grid container direction="column" flex={1} sx={{ mt: { xs: '2.3rem', md: '0rem' } }}>
        {isLoaded ? (
          <>
            <ChatHeader chat={activeChat!} users={users} myId={myUserId!} />
            <MessageList messages={activeChat?.messages} myUserId={myUserId!} users={users} />
            <MessageInput addMessage={addMessage} myUserId={myUserId!} />
          </>
        ) : (
          <>
            <Skeleton variant="text" width="50%" height={45} animation="wave" sx={{ m: 2 ,mt:3,mb:0}} />
            <Skeleton variant="text" width="30%" height={30} animation="wave" sx={{ mx: 2 ,my:0}} />
            <Box flex={1} overflow="auto" p={2} mt={2}>
              <Skeleton
                variant="rounded"
                width="60%"
                height="70px"
                animation="wave"
                sx={{ mb: 1 }}
              />
              <Skeleton
                variant="rounded"
                width="50%"
                height="50px"
                animation="wave"
                sx={{ mb: 1 }}
              />
              <Skeleton
                variant="rounded"
                width="70%"
                height="80px"
                animation="wave"
                sx={{ mb: 1 }}
              />
              <Skeleton
                variant="rounded"
                width="70%"
                height="50px"
                animation="wave"
                sx={{ mb: 1,mt:3,ml:"30%"}}
              />
              <Skeleton
                variant="rounded"
                width="50%"
                height="70px"
                animation="wave"
                sx={{ mb: 1,ml:"50%"}}
              />
              <Skeleton
                variant="rounded"
                width="30%"
                height="70px"
                animation="wave"
                sx={{ mb: 1,ml:"70%"}}
              />
            </Box>
            <Skeleton
              variant="rounded"
              width="100%"
              height="50px"
              animation="wave"
              sx={{ m: 2 ,mb:4}}
            />
          </>
        )}
      </Grid>
      {!isMobile && isLoaded ? (
        <MessagesHelper chat={activeChat!} users={users} myId={myUserId!} />
      ) : (
        !isMobile && (
          <Box width="20vw" bgcolor="background.paper" sx={{ overflowY: 'hidden' }}>
            <Box
              p={2}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              height={'96%'}
            >
              <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
                <Skeleton variant="text" width="80%" height={30} animation="wave" sx={{ mb: 2 }} />
                <Skeleton variant="circular" width={60} height={60} animation="wave" sx={{ mb: 2 }} />
                <Skeleton variant="text" width="60%" height={30} animation="wave" sx={{ mb: 0 }} />
                <Skeleton variant="text" width="60%" height={50} animation="wave" sx={{ mb: 2 }} />
              </Box>
              <Box sx={{ m: 2 }}>
                <Skeleton variant="text" width="80%" height={30} animation="wave" sx={{ mb: 2 }} />
                <Grid container spacing={1}>
                  {Array.from(new Array(4)).map((_, index) => (
                    <Grid item xs={6} key={index}>
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height={80}
                        animation="wave"
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default MessagesPage;
