import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ClearPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => caches.delete(cacheName));
    });
    navigate("/home"); // Redirect to the homepage
  }, [navigate]);

  return null; // This page doesn't render anything
};

export default ClearPage;
