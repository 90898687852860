import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Switch, FormControlLabel, Snackbar, SnackbarContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CustomTextField from './CustomTextField';
import CustomButton from '../CustomButton';
import { updateNotificationStatus, logoutUser, fetchUserId, fetchProfile, changePassword, deleteAccount } from '../../api/apiService';  // Adjust import path as needed
import { useNavigate } from 'react-router-dom';
import { colors } from '../../config/theme';

const AccountForm: React.FC = () => {
  const [notificationOn, setNotificationOn] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);  // State for dialog
  const navigate = useNavigate(); 

  useEffect(() => {
    const initializeUserProfile = async () => {
      try {
        const id = await fetchUserId();
        setUserId(id);
        const profileData = await fetchProfile(id);
        setNotificationOn(profileData.notification_on);
      } catch (error) {
        console.error('Error initializing user profile:', error);
      }
    };

    initializeUserProfile();
  }, []);

  const handleNotificationChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userId === null) {
      console.error('User ID is null');
      setSnackbarMessage('User ID is not available.');
      setSnackbarOpen(true);
      return;
    }

    const newStatus = event.target.checked;
    setNotificationOn(newStatus);

    try {
      await updateNotificationStatus(userId, newStatus);
      setSnackbarMessage('Notification status updated successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating notification status:', error);
      setSnackbarMessage('Failed to update notification status.');
      setSnackbarOpen(true);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      setSnackbarMessage('Logged out successfully!');
      setSnackbarOpen(true);
      navigate('/home');
    } catch (error) {
      console.error('Error logging out:', error);
      setSnackbarMessage('Failed to log out.');
      setSnackbarOpen(true);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setSnackbarMessage('New passwords do not match.');
      setSnackbarOpen(true);
      return;
    }

    try {
      await changePassword(newPassword);
      setSnackbarMessage('Password changed successfully!');
      setSnackbarOpen(true);
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      setSnackbarMessage('Failed to change password.');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount();
      setSnackbarMessage('Account deleted successfully!');
      setSnackbarOpen(true);
      navigate('/');  // Redirect to the home page or login page after deletion
    } catch (error) {
      console.error('Error deleting account:', error);
      setSnackbarMessage('Failed to delete account.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const openDeleteDialog = () => {
    setDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDialogOpen(false);
  };

  const confirmDeleteAccount = () => {
    closeDeleteDialog();
    handleDeleteAccount();
  };

  return (
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: '0 auto' }}>
      <Typography variant="h6">Account Settings</Typography>
      <CustomTextField
        label="New Password"
        type="password"
        fullWidth
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <CustomTextField
        label="Confirm New Password"
        type="password"
        fullWidth
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />
      <FormControlLabel
        control={<Switch checked={notificationOn} onChange={handleNotificationChange} />}
        label="Enable Notifications"
      />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomButton variant="contained" color="primary" onClick={handleChangePassword}>
          Change Password
        </CustomButton>
        <CustomButton
          variantType="outlined"
          color="secondary"
          sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}
        >
          Manage Notifications
        </CustomButton>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
        <CustomButton variant="contained" color="primary" onClick={handleLogout}>
          Logout
        </CustomButton>
        <Button
          variant="contained"
          color="secondary"
          onClick={openDeleteDialog}  // Open dialog on click
          sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}
        >
          Delete Account
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent message={snackbarMessage} />
      </Snackbar>
      <Dialog
        open={dialogOpen}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}  style={{color:colors.brandGray50}}>
            Cancel
          </Button>
          <Button onClick={confirmDeleteAccount} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountForm;
