import React, { useState } from 'react'
import { Container, Typography, Tabs, Tab, Box, Skeleton } from '@mui/material'
import PostList from '../CompLists/PostList'
import CreatePostButton from '../components/CreatePostBtn'
import { colors } from '../config/theme'
import useUpdateLocation from 'src/hooks/useUpdateLocation'

const HomePage: React.FC = () => {
  const [value, setValue] = useState(0)
  const [postType, setPostType] = useState('all')
  const { loading } = useUpdateLocation()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (newValue === 0) {
      setPostType('all')
    } else if (newValue === 1) {
      setPostType('request')
    } else if (newValue === 2) {
      setPostType('offer')
    } else if (newValue === 3) {
      setPostType('friends')
    }
  }

  return (
    <Container disableGutters sx={{ padding: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
          mb: 2,
          alignItems: 'center',
        }}
      >
        {loading ? (
          <Skeleton variant="text" width={210} height={40} />
        ) : (
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
            Feeds
          </Typography>
        )}
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            pb: { md: 1, lg: 1, xl: 1 },
            '& .MuiTab-root': {
              padding: '0px 8px',
              minWidth: 'auto',
              '&.Mui-selected': { color: colors.brandSecondary },
            },
            '& .MuiTabs-indicator': { backgroundColor: colors.brandSecondary },
          }}
        >
          <Tab label="All" sx={{ fontSize: '0.7rem', fontWeight: 600 }} />
          <Tab label="Requests" sx={{ fontSize: '0.7rem', fontWeight: 600 }} />
          <Tab label="Offers" sx={{ fontSize: '0.7rem', fontWeight: 600 }} />
          <Tab label="Friends" sx={{ fontSize: '0.7rem', fontWeight: 600 }} />
        </Tabs>
      </Box>
      <Box>
        {loading ? (
          <Skeleton variant="rectangular" height={40} width={140} />
        ) : (
          <CreatePostButton />
        )}
      </Box>
      <Box mt={1}>
        <PostList
          showButton={false}
          isFeedPage={true}
          postType={postType}
          loading={loading}
        />
      </Box>
    </Container>
  )
}

export default HomePage
