import { useEffect, useState } from 'react'
import { fetchProfile, fetchUserId } from 'src/api/apiService'
import { Profile } from 'src/types/models'

const useMyProfile = () => {
  const [userId, setUserId] = useState<number | null>(null)
  const [profile, setProfile] = useState<Profile | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initializeUserProfile = async () => {
      setLoading(true)
      try {
        const id = await fetchUserId()
        setUserId(id)
        const profileData = await fetchProfile(id)
        setProfile(profileData)
      } catch (error) {
        console.error('Error initializing user profile:', error)
      }
      setLoading(false)
    }
    initializeUserProfile()
  }, [])
  return { profile, userId, loading }
}
export default useMyProfile
