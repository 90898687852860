import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { colors } from "../../config/theme";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { fetchUserId, updateProfile } from "src/api/apiService";

const AVATAR_IMAGES = [
  "https://res.cloudinary.com/dgvzpfjsu/image/upload/v1731632367/av1.jpg",
  "https://res.cloudinary.com/dgvzpfjsu/image/upload/v1731632367/av2.jpg",
  "https://res.cloudinary.com/dgvzpfjsu/image/upload/v1731632367/av3.jpg",
  "https://res.cloudinary.com/dgvzpfjsu/image/upload/v1731632367/av4.jpg",
  "https://res.cloudinary.com/dgvzpfjsu/image/upload/v1731632367/av5.jpg",
  "https://res.cloudinary.com/dgvzpfjsu/image/upload/v1731632367/av6.jpg",
];

type FormDialogProps = {
  open: boolean;
  handleClose: () => void;
  isSignIn: boolean;
  toggleForm: () => void;
  setSignIn: React.Dispatch<React.SetStateAction<any>>;
};
type SignInData = {
  username: string;
  password: string;
};

type SignUpData = {
  username: string;
  email: string;
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
};

const StyledBox = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  width: "100%",
  marginTop: "70px",
});

const FormDialog: React.FC<FormDialogProps> = ({
  open,
  handleClose,
  isSignIn,
  toggleForm,
  setSignIn,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [generalError, setGeneralError] = useState<string | null>(null); // Add this state
  const navigate = useNavigate(); // Updated hook

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleError = (error: any) => {
    if (error.response && error.response.data) {
      setErrors(error.response.data);
      if (error.response.data.non_field_errors) {
        setGeneralError(error.response.data.non_field_errors[0]); // Set general error message
      } else {
        setGeneralError(null); // Clear general error if there's none
      }
    } else {
      console.error(error);
      setGeneralError("An unexpected error occurred. Please try again."); // Set a general fallback error
    }
  };

  const updateProfileAfterSignUp = async () => {
    console.log("Updating profile after sign-up...");

    // Select a random avatar image
    const randomAvatar =
      AVATAR_IMAGES[Math.floor(Math.random() * AVATAR_IMAGES.length)];

    // Fetch the user ID
    const userId = await fetchUserId();
    console.log("User ID:", userId);

    // Create FormData and append user information
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);

    // Fetch the avatar image as a blob
    const avatarBlob = await fetch(randomAvatar).then((res) => res.blob());

    // Convert the blob to a File object
    const avatarFile = new File([avatarBlob], "avatar.jpg", {
      type: avatarBlob.type,
    });

    // Append the avatar file to the form data
    formData.append("profile_pic", avatarFile);

    // Send the profile update request
    const res = await updateProfile(userId, formData);
    console.log("Profile updated with avatar:", res.data);
  };

  const handleSignIn = async (endpoint: string, data: SignInData) => {
    try {
      const response = await axios.post(endpoint, data);
  
      setErrors({});
      setGeneralError(null); // Clear any previous general error
  
      // Handle successful login (store token)
      localStorage.setItem("authToken", response.data.key);
      localStorage.setItem("authTokenTimestamp", Date.now().toString()); // Save token timestamp
  
      // Check if profile update is needed
      const needsProfileUpdate = localStorage.getItem("needsProfileUpdate");
      if (needsProfileUpdate === "true") {
        await updateProfileAfterSignUp();
        localStorage.removeItem("needsProfileUpdate");
      }
      navigate("/"); // Redirect to home page
    } catch (error: any) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
        if (error.response.data.non_field_errors) {
          setGeneralError(error.response.data.non_field_errors[0]); // Set general error message
        } else {
          setGeneralError(null); // Clear general error if there's none
        }
      } else {
        console.error(error);
        setGeneralError("An unexpected error occurred. Please try again."); // Set a general fallback error
      }
    }
  };
  
  const handleSignUp = async (endpoint: string, data: SignUpData) => {
    try {
      await axios.post(endpoint, data);

      setErrors({});
      setGeneralError(null); // Clear any previous general error

      // Handle successful signup (store token, set flag for profile update)
      localStorage.setItem("needsProfileUpdate", "true");
      await handleSignIn("dj-rest-auth/login/", {
        username: data.username,
        password: data.password1,
      });
    } catch (error: any) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
        if (error.response.data.non_field_errors) {
          setGeneralError(error.response.data.non_field_errors[0]); // Set general error message
        } else {
          setGeneralError(null); // Clear general error if there's none
        }
      } else {
        console.error(error);
        setGeneralError("An unexpected error occurred. Please try again."); // Set a general fallback error
      }
    }
  };

  const handleFormSubmit = async () => {
    const signInEndpoint = "dj-rest-auth/login/";
    const signUpEndpoint = "dj-rest-auth/registration/";

    if (isSignIn) {
      const signInData: SignInData = { username: email, password };
      await handleSignIn(signInEndpoint, signInData);
    } else {
      const signUpData: SignUpData = {
        username: email,
        email,
        password1: password,
        password2: password,
        first_name: firstName,
        last_name: lastName,
      };
      await handleSignUp(signUpEndpoint, signUpData);
    }
  };

  const reachGoogle = () => {
    const clientID =
      "537291185984-n996uq8mmjqlqomp7t008ul2r2dbcph3.apps.googleusercontent.com";
    const callBackURI = "https://www.freelifenation.com/home";
    const scopes = "openid email profile";
    window.location.replace(
      `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${callBackURI}&prompt=consent&response_type=token&client_id=${clientID}&scope=${scopes}`,
    );
  };

  // Function to handle Google sign-in
  const handleGoogleSignIn = async (accessToken: string) => {
    try {
      // Fetch user profile information from Google
      const googleUserProfileResponse = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" +
          accessToken
      );
      const googleUserProfile = await googleUserProfileResponse.json();
  
      // Extract relevant fields
      const { given_name, family_name, email, picture } = googleUserProfile;
      console.log(
        "Google user profile:",
        picture,
        given_name,
        family_name,
        email
      );
  
      // Send the profile info to your backend to authenticate and get the token
      const response = await axios.post("dj-rest-auth/google/", {
        access_token: accessToken,
      });
  
      // Store the authentication token
      localStorage.setItem("authToken", response.data.key);
      localStorage.setItem("authTokenTimestamp", Date.now().toString()); // Save token timestamp
  
      // Add a delay before making authenticated requests to give the backend time to process the token
      await new Promise((resolve) => setTimeout(resolve, 700)); // 700ms delay
  
      // Update the profile with the extracted information
      const userId = await fetchUserId();
      const formData = new FormData();
      formData.append("first_name", given_name);
      formData.append("last_name", family_name);
      formData.append("email", email);
  
      if (picture) {
        // Fetch the image as a blob
        const imageBlob = await fetch(picture).then((res) => res.blob());
  
        // Convert the blob to a File object with a specific name and type
        const imageFile = new File([imageBlob], "profile_pic.jpg", {
          type: imageBlob.type,
        });
  
        // Append the File object to formData
        formData.append("profile_pic", imageFile);
        console.log(imageFile); // Verify the file in console
      }
  
      const updateResponse = await updateProfile(userId, formData);
      console.log("Profile updated after Google sign-in:", updateResponse);
  
      // Navigate to the home page
      navigate("/");
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      handleError(error);
    }
  };
  

  // Handle the OAuth callback
  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substr(1));
    const accessToken = hashParams.get("access_token");

    if (accessToken) {
      handleGoogleSignIn(accessToken);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{isSignIn ? "Sign In" : "Sign Up"}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<GoogleIcon />}
            fullWidth
            onClick={reachGoogle}
            sx={{ mb: 2, color: "black", borderColor: colors.brandGray50 }}
          >
            {isSignIn ? "Sign In with Google" : "Sign Up with Google"}
          </Button>
          {/* <Button
            variant="outlined"
            startIcon={<AppleIcon />}
            fullWidth
            sx={{ mb: 2, color: 'black', borderColor: colors.brandGray50 }}
          >
            {isSignIn ? 'Sign In with Apple' : 'Sign Up with Apple'}
          </Button> */}
          <Typography variant="body1" sx={{ mb: 2 }}>
            - OR -
          </Typography>
          {generalError && ( // Display general error message
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {generalError}
            </Typography>
          )}
          {!isSignIn && (
            <>
              <TextField
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!!errors.full_name}
                helperText={errors.full_name?.[0]}
              />
              <TextField
                margin="dense"
                label="Last Name"
                type="text"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={!!errors.full_name}
                helperText={errors.full_name?.[0]}
              />
            </>
          )}
          <TextField
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.username || !!errors.email}
            helperText={errors.username?.[0] || errors.email?.[0]}
          />
          <TextField
            margin="dense"
            label="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            error={!!errors.password1 || !!errors.password}
            helperText={errors.password1?.[0] || errors.password?.[0]}
          />
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ mt: 2, boxShadow: "none" }}
            onClick={handleFormSubmit}
          >
            {isSignIn ? "Sign In" : "Sign Up"}
          </Button>
          <Button onClick={toggleForm} sx={{ mt: 2, color: "black" }}>
            {isSignIn
              ? "Don't have an account? Sign Up"
              : "Already have an account? Sign In"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
