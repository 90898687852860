import React, { useState, useEffect, useCallback } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Slider, Typography, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../config/theme';
import debounce from 'lodash.debounce';
import AWS from 'aws-sdk';

const categories = [
  'Home goods', 'Free stuff', 'Clothing', 'Electronics',
  'Pet supplies', 'Sport', 'Books', 'Toys', 'Tools',
  'Furniture', 'Appliances', 'Garden', 'Automotive',
  'Health', 'Beauty', 'Groceries', 'Music', 'Movies'
];

const CustomSlider = styled(Slider)({
  color: colors.brandGrayBlue,
  height: 2,
  '& .MuiSlider-thumb': {
    backgroundColor: colors.brandGray50,
    width: 16,
    height: 16,
    '&:hover, &.Mui-focusVisible, &.Mui-active': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-track': {
    backgroundColor: colors.brandGrayBlue,
    height: 1,
  },
  '& .MuiSlider-rail': {
    backgroundColor: colors.brandGray50,
  },
  '& .MuiSlider-valueLabel': {
    color: 'black',
    backgroundColor: colors.brandGrayBlue,
    fontSize: '13px'
  },
});

const CustomCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: colors.brandGrayBlue,
  },
});

const CustomScrollbar = styled('div')({
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#DAEBEE',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#9c27b0',
    borderRadius: '10px',
  },
});

interface ShopHelperProps {
  shops: any[];
  setFilteredShops: (shops: any[]) => void;
}

const ShopHelper: React.FC<ShopHelperProps> = ({ shops, setFilteredShops }) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [distance, setDistance] = useState<number[]>([0, Infinity]);
  const [priceRange, setPriceRange] = useState<number[]>([50, 200]);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error("Error getting user location: ", error);
      }
    );
  }, []);

  const handleCategoryChange = (category: string) => {
    setSelectedCategories(prev =>
      prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
  };

  const handleDistanceChange = (event: Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    if (Array.isArray(newValue) && newValue[1] === 100) {
      setDistance([0, Infinity]); // Setting to Infinity for "Any" distance
    } else {
      setDistance(newValue as number[]);
    }
  };

  const handlePriceChange = (event: Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };

  const getCoordinatesFromAddress = async (address: string) => {
    AWS.config.update({
      accessKeyId: 'AKIA4YS6IJCQXSEKQHG2',
      secretAccessKey: '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',
      region: 'us-east-1'
    });

    const location = new AWS.Location();

    const params = {
      IndexName: 'FreeLifeNation',
      Text: address,
    };

    try {
      const response = await location.searchPlaceIndexForText(params).promise();
      console.log("AWS Location Service Response:", response);

      if (response.Results && response.Results.length > 0) {
        const { Geometry } = response.Results[0].Place;
        if (Geometry && Geometry.Point) {
          return {
            lat: Geometry.Point[1],
            lng: Geometry.Point[0]
          };
        }
      }
      return null;
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const calculateDistance = (lat1: number, lng1: number, lat2: number, lng2: number) => {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const filterShops = useCallback(debounce(async () => {
    let filtered = shops;

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(shop =>
        selectedCategories.includes(shop.category)
      );
    }

    if (userLocation && distance[1] !== Infinity) {
      const shopPromises = filtered.map(async (shop) => {
        const shopLocation = await getCoordinatesFromAddress(shop.location);
        if (shopLocation) {
          const shopDistance = calculateDistance(
            userLocation.lat,
            userLocation.lng,
            shopLocation.lat,
            shopLocation.lng
          );
          return { ...shop, distance: shopDistance };
        }
        return shop;
      });

      filtered = (await Promise.all(shopPromises)).filter(
        (shop) => shop.distance !== undefined && shop.distance <= distance[1]
      );
    }

    setFilteredShops(filtered);
  }, 500), [selectedCategories, distance, shops, userLocation, setFilteredShops]);

  useEffect(() => {
    filterShops();
  }, [selectedCategories, distance, shops, userLocation, filterShops]);

  return (
    <Box sx={{ backgroundColor: '#DAEBEE', borderRadius: '10px', height: '100%', width: '20vw' }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "600" ,pt: '20px',pr: '20px',pl: '20px'}} >
        Filters
      </Typography>

      <Typography variant="subtitle1" gutterBottom mt={2} sx={{pr: '20px',pl: '20px'}}>
        All categories
      </Typography>
      <CustomScrollbar sx={{pr: '20px',pl: '20px'}}>
        <FormGroup sx={{ maxHeight: '36vh', overflowY: 'scroll', flexWrap: 'nowrap', pl: 1 }}>
          {categories.map((category, index) => (
            <FormControlLabel
              key={index}
              control={
                <CustomCheckbox
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCategoryChange(category)}
                />
              }
              label={category}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                  color: colors.brandGray
                }
              }}
            />
          ))}
        </FormGroup>
      </CustomScrollbar>

      <Divider sx={{ my: 2, backgroundColor: 'black',mr: '20px',ml: '20px' }}/>

      <Box sx={{ mt: 3 ,pr: '20px',pl: '20px' }}>
        <Typography gutterBottom>
          Distance (in km)
        </Typography>
        <CustomSlider
          value={distance[1] === Infinity ? [0, 100] : distance}
          onChange={handleDistanceChange}
          aria-labelledby="distance-slider"
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={100}
          valueLabelFormat={(value) => (value === 100 ? 'Any' : value)}
        />
        <Typography>
          {distance[1] === Infinity ? 'Any distance' : `${distance[1]} km`}
        </Typography>
      </Box>

      <Divider sx={{ my: 2, backgroundColor: 'black' ,mr: '20px',ml: '20px' }} />

      <Box sx={{ mt: 3 ,pr: '20px',pl: '20px' }}>
        <Typography gutterBottom>
          Price
        </Typography>
        <CustomSlider
          value={priceRange}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          min={0}
          max={1000}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <TextField
            label="From"
            type="number"
            value={priceRange[0]}
            onChange={(e) => setPriceRange([+e.target.value, priceRange[1]])}
            InputProps={{ inputProps: { min: 0, max: priceRange[1] }, sx: { py: '0px' } }}
            sx={{
              '& .MuiInputBase-root': { padding: '0 0', alignItems: 'start' },
              '& .MuiInputBase-input': { padding: '10px 20px' }
            }}
          />
          <TextField
            label="To"
            type="number"
            value={priceRange[1]}
            onChange={(e) => setPriceRange([priceRange[0], +e.target.value])}
            InputProps={{ inputProps: { min: priceRange[0], max: 1000 } }}
            sx={{
              '& .MuiInputBase-root': { padding: '0 0', alignItems: 'start' },
              '& .MuiInputBase-input': { padding: '10px 20px' }
            }}
          />
        </Box>
        <Divider sx={{ my: 2, backgroundColor: 'black' }} />
      </Box>
    </Box>
  );
};

export default ShopHelper;
